import React, {Component} from 'react'
// import {InputText} from "primereact/inputtext"
import {TreeTable} from 'primereact/treetable';
import {Column} from 'primereact/column'

// import {formatDate,formatMoney} from '../helpers/formats'
import {API_URL} from '../helpers/api_helpers'

import axios from 'axios';

export class CheckLogCleared extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			globalFilter: null
		}
	}

	componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.check_log_view === false || permissions.check_log_view === undefined ) {
			window.location = "/"
		}
    this.getCheckLog()
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	getCheckLog(){
		var url = API_URL + "/api/v1/check_log/by_year"
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				// console.log(res)
				this.setState({data: res.data});
			}
		})
	}

	render() {
		return (
			<div className="p-grid" style={{width:"100%"}}>
				<div className="p-col-12 client-info">
					<div className="card" style={{display:"flex"}}>
						<div className="p-grid" style={{width:"100%"}}>
							<div className="p-col-12">
								<h1>Cleared Checks</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="p-col-12">
					<div className="card">
						<TreeTable value={this.state.data}>
							<Column field="year" header="Year" expander></Column>
							<Column field="account" header="Account" sortable={true}></Column>
							<Column field="firstname" header="First Name" sortable={true}></Column>
							<Column field="lastname" header="Last Name" sortable={true}></Column>
							<Column field="amount" header="Amount" sortable={true}></Column>
							<Column field="check_number" header="Check Number" sortable={true}></Column>
							<Column field="received_date" header="Received Date" sortable={true}></Column>
							<Column field="checkdate" header="Check Date" sortable={true}></Column>
						</TreeTable>
					</div>
				</div>
			</div>
		);
	}
}