import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';
import {formatMoney} from '../helpers/formats'

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';


export class Transfer extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
		}
		this.transferFunds = this.transferFunds.bind(this)
	}

	componentWillMount(){
		this.getClients()
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	getClients(trust_id){
		var status = 'active'
		var url = API_URL + "/api/v1/clients?status=" + status;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var clients = res.data.clients.map((e) => {
					return {label: e.account + ": " + e.firstname + " " + e.lastname, value: e.id}
				})
				var current = clients.filter((val,i) => val.value == this.props.client.id)
				if (current.length > 0){
					// console.log(current)
					this.setState({clients: clients, client_list: res.data.clients, selected_client: current[0].value });
				}
			}
		});	
	}

	transferFunds(){
		var data = {
			date: this.state.date,
			selected_client: this.state.selected_client,
			selected_to_client: this.state.selected_to_client,
			amount: this.state.amount,
			memo: this.state.memo,
			move: this.state.moveFunds,
		}
		var url = API_URL + "/api/v1/transfer"
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				// console.log(res)
				if (res.data.message === "Transfer move successful!") {
					this.growl.show({severity: 'success', summary: 'Success', detail: res.data.message});
				} else {
					this.growl.show({severity: 'error', summary: 'Failure', detail: res.data.message});
				}
			}
		})	
	}

	render(){
		return(
			<div className="p-grid p-justify-left">
					<Growl ref={(el) => this.growl = el} />
					<div className="p-col-12 p-md-8">
						<div>
							<h3 style={{margin:"0px"}}>Date</h3>
							<Calendar value={this.state.date} onChange={(e) => this.setState({date: e.value})}></Calendar>
						</div>

						<div>
							<h3 style={{margin:"0px"}}>Transfer From</h3>
							<Dropdown value={this.state.selected_client} options={this.state.clients} style={{width:"380px"}} onChange={(e) => {this.setState({selected_client: e.value})}} filter={true} filterPlaceholder="Select Client" filterBy="account" placeholder="Select a Client"/>
						</div>

						<div>
							<h3 style={{margin:"0px"}}>Transfer To</h3>
							<Dropdown value={this.state.selected_to_client} options={this.state.clients} style={{width:"380px"}} onChange={(e) => {this.setState({selected_to_client: e.value})}} filter={true} filterPlaceholder="Select Client" filterBy="account" placeholder="Select a Client"/>
						</div>

						<div>
							<h3 style={{margin:"0px"}}>Amount</h3>
							<InputText id="amount" onChange={this.handleChange} placeholder="Amount" style={{height:"35px"}}/>
						</div>

						<div>
							<h3 style={{margin:"0px"}}>Memo</h3>
							<InputText id="memo" onChange={this.handleChange} placeholder="Memo" style={{height:"35px"}}/>
						</div>

						<Button label="Transfer" icon="pi pi-check" onClick={this.transferFunds}/><Checkbox style={{marginLeft: "10px"}} checked={this.state.moveFunds} onChange={e => this.setState({moveFunds: e.checked})} /> Move Funds (doesn't create transactions)
					</div>
				</div>
		)
	}
}