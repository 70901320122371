import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog} from "primereact/dialog";
// import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar'
import {Growl} from 'primereact/growl';

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';


export class WelcomePackets extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			display: false,
			county: 1,
			trustee1: "Rhonda Frederick",
			trustee2: "Karen Nicolson",
			trustee3: "Stephanie Toth",
		}
		this.generateWelcomePacket = this.generateWelcomePacket.bind(this)
	}

	handleChange = event => {
		console.log(event.target.id)
		console.log(event.target.value)
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	getCounties(){
		var url = API_URL + "/api/v1/counties";
    axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      }
    })
    .then(({ data }) => {
      var dataSet = [];
      console.log(data);
      data.counties.map(function(v,i){
        // console.log(v);
       return dataSet.push({label: v.name + " " + v.city, value: v.id});
      });
      this.setState({
        counties: dataSet,
      });
    });
	}

	getInfo(){
		var url = API_URL + "/api/v1/welcome_packets/?client_id=" + this.props.client.id;
    axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      }
    })
    .then(({ data }) => {
    	var info = data.welcome_packet[0]
    	if ( info != undefined ) {
	      this.setState({
	        executed: info.executed_by,
	        executedDate: new Date(info.executed_date),
	        trustee1: info.trustee_1,
	        trustee2: info.trustee_2,
	        trustee3: info.trustee_3,
	        trustee1Executed: new Date(info.t1_executed),
	        trustee2Executed: new Date(info.t2_executed),
	        trustee3Executed: new Date(info.t3_executed),
	        trustOpened: new Date(info.opened),
	        dateOfDeposit: new Date(info.deposit),
	        county: info.county,
	        info: info,
	      });
	    }
    });
	}

	generateWelcomePacket(){
		// First we will save the data or update it
		if ( this.state.info ) {
			var url = API_URL + "/api/v1/welcome_packets/" + this.state.info.id
			var data = {
				client_id: this.props.client.id, 
				executed_by: this.state.executed,
				trustee_1: this.state.trustee1,
				trustee_2: this.state.trustee2,
				trustee_3: this.state.trustee3,
				t1_executed: this.state.trustee1Executed,
				t2_executed: this.state.trustee2Executed,
				t3_executed: this.state.trustee3Executed,
				executed_date: this.state.executedDate,
				opened: this.state.trustOpened,
				deposit: this.state.dateOfDeposit,
				county: this.state.county,
			}
	    axios.put(url, data, {
	      headers: {
	        Authorization: sessionStorage.getItem('jwtToken'),
	      }
	    })
	    .then(({ data }) => {
	      this.setState({
	        info: data,
	      });
	    });
		} else {
			var url = API_URL + "/api/v1/welcome_packets/"
			var data = {
				client_id: this.props.client.id, 
				executed_by: this.state.executed,
				trustee_1: this.state.trustee1,
				trustee_2: this.state.trustee2,
				trustee_3: this.state.trustee3,
				t1_executed: this.state.trustee1Executed,
				t2_executed: this.state.trustee2Executed,
				t3_executed: this.state.trustee3Executed,
				executed_date: this.state.executedDate,
				opened: this.state.trustOpened,
				deposit: this.state.dateOfDeposit,
				county: this.state.county,
			}
	    axios.post(url, data, {
	      headers: {
	        Authorization: sessionStorage.getItem('jwtToken'),
	      }
	    })
	    .then(({ data }) => {
	      this.setState({
	        info: data,
	      });
	    });
	  }
		// Then Generate the packet.
		var url = API_URL + "/api/v1/clients/welcomepacket";
		var data = {
			client_id: this.props.client.id,
			county: this.state.county,
			executed_by: this.state.executed,
			executed_date: this.state.executedDate,
			trustee1: this.state.trustee1,
			trustee2: this.state.trustee2,
			trustee3: this.state.trustee3,
			trust_opened: this.state.trustOpened,
			date_of_deposit: this.state.dateOfDeposit,
			trustee1_executed: this.state.trustee1Executed,
			trustee2_executed: this.state.trustee2Executed,
			trustee3_executed: this.state.trustee3Executed,
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			console.log(res)
			if (res.data.message === "Missing Documents") {
				this.growl.show({severity: 'warn', summary: 'Warn', detail: res.data.message});
			} else {
				this.growl.show({severity: 'success', summary: 'Success', detail: res.data.message});
			}
			this.setState({display: false})
		})
		.catch( ( error ) => {
			console.log(error);
			this.growl.show({severity: 'success', summary: 'Success', detail: error});
			this.setState({display: false})
		})
		.then( ( res ) => {
			// always executed
		});
	}

	UNSAFE_componentWillMount(){
		this.setState({executed: this.props.client.firstname + " " + this.props.client.lastname})
		this.getCounties()
		this.getInfo()
	}

	render(){
		const dialogHeader = (
			<div>
				<h1>Welcome Packets</h1>
			</div>
		);

		return (
			<React.Fragment>
				<Growl ref={(el) => this.growl = el} />
				<button className="p-button p-button-warning default-btn" onClick={() => this.setState({display: true})}>Generate Welcome Packets</button>
				<Dialog
					header={dialogHeader}
					visible={this.state.display}
					modal={true}
					dismissableMask={true}
					closable={true}
					className="documents-dialog"
					onHide={ () => this.setState({display: false}) }
				>
					<div className="card card-w-title">
						<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
							
							<div className="p-col-4">
								<span>
									<label htmlFor="county">Select a County</label>
									<Dropdown id="county" value={this.state.county} options={this.state.counties} onChange={(e) => {this.setState({county: e.value})}} style={{width:'300px'}}/>
								</span>
							</div>
							
							<div className="p-col-4">
								<span>
									<label htmlFor="executed">Executed By</label>
									<InputText id="executed" value={this.state.executed} onChange={this.handleChange} />
								</span>
							</div>
							
							<div className="p-col-4">
								<span style={{width:"100%"}}>
									<label htmlFor="executedDate">Executed Date</label>
									<Calendar id="executedDate" value={this.state.executedDate} onChange={(e) => this.setState({executedDate: e.value})} style={{marginTop:0,width:"100%"}}></Calendar>
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="trustee1">Trustee 1</label>
									<InputText id="trustee1" value={this.state.trustee1} onChange={(e) => this.setState({trustee1: e.target.value})} />
								</span>
							</div>
							
							<div className="p-col-4">
								<span>
									<label htmlFor="trustee2">Trustee 2</label>
									<InputText id="trustee2" value={this.state.trustee2} onChange={(e) => this.setState({trustee2: e.target.value})} />
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="trustee3">Trustee 3</label>
									<InputText id="trustee3" value={this.state.trustee3} onChange={(e) => this.setState({trustee3: e.target.value})} />
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="trustee1Executed">Trustee 1 Executed</label>
									<Calendar id="trustee1Executed" value={this.state.trustee1Executed} onChange={(e) => this.setState({trustee1Executed: e.value})} style={{marginTop:0,width:"100%"}}></Calendar>
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="trustee2Executed">Trustee 2 Executed</label>
									<Calendar id="trustee2Executed" value={this.state.trustee2Executed} onChange={(e) => this.setState({trustee2Executed: e.value})} style={{marginTop:0,width:"100%"}}></Calendar>
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="trustee3Executed">Trustee 3 Executed</label>
									<Calendar id="trustee3Executed" value={this.state.trustee3Executed} onChange={(e) => this.setState({trustee3Executed: e.value})} style={{marginTop:0,width:"100%"}}></Calendar>
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="trustOpened">Month Year Trust Was Opened</label>
									<Calendar id="trustOpened" value={this.state.trustOpened} onChange={(e) => this.setState({trustOpened: e.value})} style={{marginTop:0}} view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2060"></Calendar>
								</span>
							</div>

							<div className="p-col-4">
								<span>
									<label htmlFor="dateOfDeposit">Date of Deposit</label>
									<Calendar id="dateOfDeposit" value={this.state.dateOfDeposit} onChange={(e) => this.setState({dateOfDeposit: e.value})} style={{marginTop:0}}></Calendar>
								</span>
							</div>
						</div>
						<button className="p-button p-button-warning default-btn" onClick={() => this.generateWelcomePacket()}>Generate Welcome Packets</button>
					</div>
				</Dialog>
			</React.Fragment>
		);
	}
}
