import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Growl} from 'primereact/growl';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {ContextMenu} from 'primereact/contextmenu';

import {API_URL} from '../helpers/api_helpers'
import './truelink.css'

import axios from 'axios';


export class TrueLink extends Component {

	constructor(props) {
		super(props);
		this.state = {
			client: null,
			date_approved: "",
			date_transferred: "",
			has_balance: "",
			date_completed: "",
			request: "",
			trueLinksSelection: "",
			contextSelection: null,
			contextItems: [],
			menu: [],
		}
		this.getClient = this.getClient.bind(this)
		this.addNew = this.addNew.bind(this)
		this.actionTemplate = this.actionTemplate.bind(this)
		this.editRow = this.editRow.bind(this)
		this.save = this.save.bind(this)
		this.update = this.update.bind(this)
		this.addNote = this.addNote.bind(this)
		this.editorForRowEditing = this.editorForRowEditing.bind(this)
		this.onRowEditInit = this.onRowEditInit.bind(this)
		this.onRowEditSave = this.onRowEditSave.bind(this)
		this.onRowEditCancel = this.onRowEditCancel.bind(this)
		this.actionTemplate2 = this.actionTemplate2.bind(this)
		this.deleteReceipt = this.deleteReceipt.bind(this)
		this.updateNote = this.updateNote.bind(this)
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	getClient(id){
		var permissions = JSON.parse(sessionStorage.permissions)
		var url = API_URL + "/api/v1/clients/"+id ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.setState({client: res.data.client });
				this.getTrueLinks(id);
				if ( permissions.true_link_edit === true ){
					this.setState({
						menu: [
							{label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (event) => this.editRow(event,this.state.contextSelection,this.props.title) },
							{label: 'Delete',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(event,this.state.contextSelection,this.props.title) } },
						]
					})
				}
			}
		});
	}

	getTrueLinks(id){
		var url = API_URL + "/api/v1/true_links?client_id=" + id;
		axios.get(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.setState({true_links: res.data.true_links})
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	editRow(event,row,category){
		this.setState(
			{
				date_approved: row.date_approved,
				date_transferred: row.date_transferred,
				has_balance: row.has_balance,
				date_completed: row.date_completed,
				request: row.request,
				updateTrueLinkRow: row.id,
				update: true,
				displayDialog: true,
			})
	}

	deleteRow(event,row,category){
		var url = API_URL + "/api/v1/true_links/"+ row.id
		axios.delete(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getTrueLinks(this.state.client.id)
		})
		.catch( ( error ) => {
			console.log(error);
		})
	}

	update(){
		var url = API_URL + "/api/v1/true_links/"+ this.state.updateTrueLinkRow;
		var data = {
			date_approved: this.state.date_approved,
			date_transferred: this.state.date_transferred,
			has_balance: this.state.has_balance,
			date_completed: this.state.date_completed,
			request: this.state.request,
			client_id: this.state.client.id,
		}
		axios.put(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getTrueLinks(this.state.client.id)
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			this.setState(
			{
				date_approved: "",
				date_transferred: "",
				has_balance: "",
				date_completed: "",
				request: "",
				updateTrueLinkRow: "",
				update: false,
				displayDialog: false,
			})
		});
	}

	save(){
		var url = API_URL + "/api/v1/true_links/"
		var data = {
			date_approved: this.state.date_approved,
			date_transferred: this.state.date_transferred,
			has_balance: this.state.has_balance,
			date_completed: this.state.date_completed,
			request: this.state.request,
			client_id: this.state.client.id,
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getTrueLinks(this.state.client.id)
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			this.setState(
			{
				date_approved: "",
				date_transferred: "",
				has_balance: "",
				date_completed: "",
				request: "",
				updateTrueLinkRow: "",
				update: false,
				displayDialog: false,
			})
		});
	}

	addNote(){
		var url = API_URL + "/api/v1/receipts/"
		var data = {
			// date_reviewed: new Date(),
			details: this.state.details,
			true_link_id: this.state.note_id,
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getTrueLinks(this.state.client.id)
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			this.setState(
			{
				date_reviewed: "",
				details: "",
				true_link_id: "",
				displayDialogNote: false,
			})
		});
	}

	componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.true_link_view === false || permissions.true_link_view === undefined ) {
			window.location = "/"
		}
		var id = this.props.match.params.id
		this.getClient(id)
	}

	actionTemplate(rowData, column) {
		return (
			<div>
				<Button type="button" icon="pi pi-pencil" onClick={() => this.setState({displayDialogNote: true, note_id: rowData.id, note_receipts: rowData.receipts})} className="p-button-warning"></Button>
			</div>
		)
	}

	deleteReceipt(rowData){
		// console.log(rowData)
		var url = API_URL + "/api/v1/receipts/" + rowData.id
		axios.delete(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getTrueLinks(this.state.client.id)
			let index = this.state.note_receipts.indexOf(rowData)
			// console.log(index)
			this.setState({
				note_receipts: this.state.note_receipts.filter((val,i) => i !== index),
			})
		})
		.catch( ( error ) => {
			console.log(error);
		})
	}

	updateNote(rowData){
		var url = API_URL + "/api/v1/receipts/" + rowData.id
		var data = {
			details: rowData.details,
		}
		axios.put(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getTrueLinks(this.state.client.id)
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			this.setState(
			{
				date_reviewed: "",
				details: "",
				true_link_id: "",
			})
		});
	}

	actionTemplate2(rowData, column) {
		return <div>
			<Button type="button" icon="pi pi-save" className="p-button-success" onClick={() => this.updateNote(rowData)} style={{marginRight: '.5em'}}></Button>
			<Button type="button" icon="pi pi-times" className="p-button-danger" onClick={() => this.deleteReceipt(rowData)}></Button>
		</div>;
	}

	notesTemplate(rowData, column) {
		let receipts = rowData.receipts.map( (k,v) => {
			return <div key={v}><span>{k.date_reviewed}</span> {k.details}</div>
		})
		return (
			<div>
				{receipts}
			</div>
		)
	}

	addNew() {
		this.setState({
			displayDialog: true
		});
	}

	onEditorValueChangeForRowEditing(props, value) {
		let updatedCars = [...props.value];
		updatedCars[props.rowIndex][props.field] = value;
		this.setState({cars2: updatedCars});
	}

	editorForRowEditing(props, field) {
		return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeForRowEditing(props, e.target.value)} />;
	}

	onRowEditInit(event) {
		this.clonedReciepts[event.data.id] = {...event.data};
	}

	onRowEditSave(event) {
		delete this.clonedReciepts[event.data.id];
		this.growl.show({severity: 'success', summary: 'Success', detail: 'Car is updated'});
	}

	onRowEditCancel(event) {
		let cars = [...this.state.cars2];
		cars[event.index] = this.clonedReciepts[event.data.id];
		delete this.clonedReciepts[event.data.id];
		this.setState({ cars2: cars })
	}

	render() {
		var permissions = JSON.parse(sessionStorage.permissions)
		if (this.state.client === null) {
			return ""
		}
		var header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>True Link Transactions <Button icon="pi pi-refresh" style={{'float':'right'}} onClick={() => this.getTrueLinks(this.state.client.id)}/></div>;
		
		let footer = <div className="p-clearfix" style={{width:'100%'}}>
			<Button style={{float:'left'}} label="Add" icon="pi pi-plus" onClick={this.addNew}/>
		</div>;
		
		const dialogFooter = (
			<div className="ui-dialog-buttonpane p-clearfix">
			{this.state.update ?
				<Button label="Update" icon="pi pi-check" onClick={this.update}/>
				:
				<Button label="Save" icon="pi pi-check" onClick={this.save}/>
			}
			</div>
		)

		let dialogFooterNote = <div className="ui-dialog-buttonpane p-clearfix">
			<Button label="Save" icon="pi pi-check" onClick={this.addNote}/>
		</div>;
		
		return (
			<div className="p-grid">
				<div className="p-col-12 client-info">
					<div className="card">
						<h1>{this.state.client.firstname} {this.state.client.lastname}</h1>
						<p style={{whiteSpace: 'pre-line',marginTop: '0px'}}>
							{this.state.client.address1+"\n"}
							{this.state.client.address2 ? this.state.client.address2+"\n" : ""}
							{this.state.client.city}, {this.state.client.state} {this.state.client.zip}
							{"\n\n"}
							<strong>Account:</strong> {this.state.client.account+"\n"}
						</p>
					</div>
				</div>

				<div className="p-col-12">
					<div className="card">
						<Growl ref={(el) => this.growl = el} />
						<div className="p-grid truelink">
							<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
							{ permissions.true_link_edit === true ?
								<DataTable
									value={this.state.true_links}
									footer={footer}
									header={header}
									contextMenuSelection={this.state.contextSelection} 
									onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
									onContextMenu={e => this.cm.show(e.originalEvent)}
								>
									<Column field="request" header="Request" sortable={false} />
									<Column field="date_approved" header="Date Approved" sortable={false} />
									<Column field="date_transferred" header="Date Transferred" sortable={false} />
									<Column field="has_balance" header="Additional Action" sortable={false} />
									<Column field="date_completed" header="Completed" sortable={false} />
									<Column field="notes" body={this.notesTemplate} header="Notes" sortable={false}/>
									<Column body={this.actionTemplate} header="Add Notes" style={{textAlign:'center', width: '8em'}}/>
								</DataTable>
								:
									<DataTable
									value={this.state.true_links}
									header={header}
								>
									<Column field="request" header="Request" sortable={false} />
									<Column field="date_approved" header="Date Approved" sortable={false} />
									<Column field="date_transferred" header="Date Transferred" sortable={false} />
									<Column field="has_balance" header="Additional Action" sortable={false} />
									<Column field="date_completed" header="Completed" sortable={false} />
									<Column field="notes" body={this.notesTemplate} header="Notes" sortable={false}/>
								</DataTable>
								}

							<Dialog className="true-link-dialog" visible={this.state.displayDialog} width="300px" header="True Link Details" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
								<span className="p-float-label" style={{marginTop:"20px"}}>
									<InputText id="request" onChange={this.handleChange} value={this.state.request ? this.state.request : "" }/>
									<label htmlFor="request">Request</label>
								</span>
								<span className="p-float-label">
									<Calendar id='date_approved' value={this.state.date_approved ? this.state.date_approved : ""} onChange={this.handleChange }></Calendar>
									<label htmlFor="date_approved">Date Approved</label>
								</span>
								<span className="p-float-label">
									<Calendar id='date_transferred' value={this.state.date_transferred ? this.state.date_transferred : ""} onChange={this.handleChange }></Calendar>
									<label htmlFor="date_transferred">Date Transferred</label>
								</span>
								<span className="p-float-label">
									<Calendar id='has_balance' value={this.state.has_balance ? this.state.has_balance : ""} onChange={this.handleChange }></Calendar>
									<label htmlFor="has_balance">Additional Action?</label>
								</span>
								<span className="p-float-label">
									<Calendar id='date_completed' value={this.state.date_completed ? this.state.date_completed : ""} onChange={this.handleChange }></Calendar>
									<label htmlFor="date_completed">Completed</label>
								</span>
							</Dialog>

							<Dialog className="true-link-dialog" visible={this.state.displayDialogNote} width="300px" header="Add or Edit Notes" modal={true} footer={dialogFooterNote} onHide={() => this.setState({displayDialogNote: false})}>
								<span className="p-float-label" style={{marginTop:"20px"}}>
									<DataTable value={this.state.note_receipts}>
										<Column field="details" editor={(props) => this.editorForRowEditing(props, 'details')} header="Notes" sortable={false}/>
										<Column body={this.actionTemplate2} header="Edit Notes" style={{textAlign:'center', width: '8em'}}/>
									</DataTable>
									<br />
									<InputText id="details" onChange={this.handleChange} value={this.state.details ? this.state.details : "" }/>
								</span>
							</Dialog>

						</div>
					</div>
				</div>
			</div>
		);
	}
}