// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docs h1 {
    margin-top: 28px;
    margin-bottom: 14px;
}

.docs pre {
    font-family: monospace;
    background-color: #dee4e9;
    color: #292B2C;
    padding: 1em;
    font-size: 14px;
    border-radius: 3px;
    overflow: auto;
}

.docs p {
    line-height: 1.5;
}

.inline-code {
    background-color: #0C2238;
    color: #dddddd;
    font-style: normal;
    font-size: 13px;
    padding: 0 .5em;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Documentation.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB","sourcesContent":[".docs h1 {\n    margin-top: 28px;\n    margin-bottom: 14px;\n}\n\n.docs pre {\n    font-family: monospace;\n    background-color: #dee4e9;\n    color: #292B2C;\n    padding: 1em;\n    font-size: 14px;\n    border-radius: 3px;\n    overflow: auto;\n}\n\n.docs p {\n    line-height: 1.5;\n}\n\n.inline-code {\n    background-color: #0C2238;\n    color: #dddddd;\n    font-style: normal;\n    font-size: 13px;\n    padding: 0 .5em;\n}\n\n.video-container {\n    position: relative;\n    width: 100%;\n    height: 0;\n    padding-bottom: 56.25%;\n}\n\n.video-container iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
