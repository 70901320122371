import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InputTextarea} from 'primereact/inputtextarea';
import {ProgressBar} from 'primereact/progressbar';
import {ClientApproval} from '../../components/ClientApproval';
import {Dialog} from "primereact/dialog";
import {Button} from 'primereact/button';
import {Upload} from '../../components/Upload';
import {Transactions} from '../../components/Transactions';
import {TransactionsFee} from '../../components/TransactionsFee';
import {TransactionsDeposit} from '../../components/TransactionsDeposit';
import {Transfer} from '../../components/Transfer';
import {AdditionalNotes} from '../../components/AdditionalNotes';
import {Details} from '../../components/Details';
import {CaseNotes} from '../../components/CaseNotes';
import {TransactionsTable} from '../../components/TransactionsTable';
import {WelcomePackets} from '../../components/WelcomePackets';
import {Summary} from '../../components/Summary';
import {Documents} from '../../components/Documents';
import {Growl} from 'primereact/growl';

import './client.css'
import './c2.scss'

import {API_URL} from '../../helpers/api_helpers'
import axios, {isCancel, AxiosError} from 'axios';


export class Client extends Component {
	static propTypes = {
	trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			client: null,
			dataTableValue: [],
			dataTableSelection: null,
			dataViewValue: [],
			classifications: [],
			deposits: { data: [], paginator: false },
			paymentsWithDeposit: { data: [], paginator: false },
			paymentsWithInvoice: { data: [], paginator: false },
			fees: { data: [], paginator: false },
			commonPayment: { data: [], paginator: false },
			files: [],
			key: ''+Math.random(),
			key1: ''+Math.random(),
			key2: ''+Math.random(),
			key3: ''+Math.random(),
			key4: ''+Math.random(),
			key5: ''+Math.random(),
			case_notes: null,
		}
		this.addDeposit = this.addDeposit.bind(this)
		this.addPayment = this.addPayment.bind(this)
		this.addFee = this.addFee.bind(this)
		this.updateParent =this.updateParent.bind(this)
		this.addNote = this.addNote.bind(this)
		this.closeDetails = this.closeDetails.bind(this)
		this.additionalNotes = React.createRef()
		this.addCaseNote = this.addCaseNote.bind(this)

		this.child = React.createRef();
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	componentWillMount(){
		this.get_export_files();
		this.setState({location: this.props.location})
		this.getClient();
		this.getclassifications();
	}
	
	get_export_files(){
		var url = API_URL + "/api/v1/export_files";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				this.setState({files: res.data.export_files})
			}
		});
	}

	getClient(){
		// var id = this.state.location ? this.state.location.state.id : 
		var url = API_URL + "/api/v1/clients/"+ this.props.location.state.id ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				res.data.client.trust = this.props.location.state.trust;
				this.setState({
					client: res.data.client,
				});
			}
		})
		.then((res) => {
			this.getClientDeposits();
			this.getClientPayments(1);
			this.getClientPayments(2);
			this.getClientFees();
			this.getClientPayments(4);
			this.getCaseNotes();
		});
	}

	getClientDeposits(){
		var url = API_URL + "/api/v1/deposits";
		var data = {
			client_id: this.state.client.id,
		};
		return axios.get(url,{ params: data,
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var deposits = this.state.deposits;
				deposits['data'] = res.data.deposits;
				if ( deposits['data'].length > 5 ) {
					deposits['paginator'] = true;
				}
				this.setState({deposits: deposits });
			}
		});
	}

	getClientPayments(payment_type){
		var url = API_URL + "/api/v1/payments?client_id=" + this.state.client.id +"&payment_type=" + payment_type ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				switch(payment_type) {
					case 1: { 
						var payment = this.state.paymentsWithDeposit;
						payment['data'] = res.data.payments;
						if ( payment['data'].length > 5 ) {
							payment['paginator'] = true;
						}
						this.setState({paymentsWithDeposit: payment });
						break; 
					} 
					case 2: {
						payment = this.state.paymentsWithInvoice;
						payment['data'] = res.data.payments;
						if ( payment['data'].length > 5 ) {
							payment['paginator'] = true;
						}
						this.setState({paymentsWithInvoice: payment }); 
						break; 
					}
					case 4: {
						payment = this.state.commonPayment;
						payment['data'] = res.data.payments;
						if ( payment['data'].length > 5 ) {
							payment['paginator'] = true;
						}
						this.setState({commonPayment: payment }); 
						break; 
					}
					default: {

					}
				} 
			}
		});
	}

	getClientFees(){
		var url = API_URL + "/api/v1/fees?client_id=" + this.state.client.id;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var fee = this.state.fees
				fee['data'] = res.data.fees;
				if ( fee['data'].length > 5 ) {
					fee['paginator'] = true;
				}
				this.setState({fees: fee });
			}
		});	
	}

	getclassifications() {
		// const history = this.props.history;
		var url = API_URL + "/api/v1/classifications";
		axios.get(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(({ data }) => {
				var dataSet = [];
				data.classifications.map(function(v,i){
				 return	dataSet.push({label: v.name, value: v.id});
				});
				this.setState({
					classifications: dataSet,
				});
			});
	}

	updateClient(){
		// const history = this.props.history;
		var url = API_URL + "/api/v1/clients/" + this.state.client.id ;
		var data = {}
		data.trust_id = this.state.client.trust.id
		data.firstname = this.state.firstname
		data.lastname = this.state.lastname
		data.address1 = this.state.address1
		data.address2 = this.state.address2
		data.city = this.state.city
		data.state = this.state.state
		data.zip = this.state.zip
		data.classification_id = this.state.classification
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.getClient();
				this.setState({displayEdit: false})
				// history.push("/"+this.props.trust.name.replace(" ","-").toLowerCase() + "/pending-clients")
			}
		});
	}

	getClientEdit(){
		var url = API_URL + "/api/v1/client/edit/form/" + this.state.client.id ;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var data = {}
				res.data.map(function(v,i){
					return Object.assign(data,v)
				});
				this.setState({properties: data })
				this.setState({displayEdit: true})
				this.properties = data;
			}
		})
	}

	addNote(){
		var url = API_URL + "/api/v1/additional_notes/";

		var data = {additional_note: {
			client_id: this.state.client.id,
			note: this.state.note
		}}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.this.getCaseNotes()
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
			this.setState({displayNotes: false, note: ""});
			this.additionalNotes.current.getClientNotes();
			document.getElementById("note-form").reset();
		});
	}

	getCaseNotes(){
		var url = API_URL + "/api/v1/client_notes?client_id="+this.state.client.id ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.setState({case_notes: res.data.client_notes });
			}
		});
	}

	addCaseNote(){
		var url = API_URL + "/api/v1/client_notes/";

		var data = {client_note: {
			client_id: this.state.client.id,
			note: this.state.casenote,
			user: sessionStorage.getItem('name'),
		}}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getClient()
			this.child.current.getCaseNotes()
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
			this.setState({displayCaseNotes: false, casenote: ""});
			document.getElementById("case-note-form").reset();
		});
	}

	getClientClassification(classification_id){
		var val = this.state.classifications.map(function(obj,i){
			if (obj.value === classification_id){
				return obj.label
			} else {
				return ''
			}
		})
		return val
	}

	addDeposit(amount,checknumber,recurring,fee,exception){

		var url = API_URL + "/api/v1/deposits/";

		var data = {deposit: {
			amount: parseFloat(amount),
			client_id: this.state.client.id,
			check_number: checknumber,
			fee: fee,
			recurring: recurring,
			exception: exception,
		}}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.getClient();
			this.addSummaryPayment(data)
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	addSummaryPayment(data){
		data.payment_id = data.id
		var url = API_URL + "/api/v1/summary_payment/";
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			console.log('added summary payment')
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	addPayment(amount,payee,method,destinationcode,mailinginfo,streetname,city,stateprovince,zippostalcode,expl1,payment_type,aptnumber,suitenumber,additionalline1,description,additional,payoraccount,payorfirstname,payorlastname){
		var url = API_URL + "/api/v1/new/payment/";
		var data = {
		 payment: {
			 amount: parseFloat(amount),
			 mnemonic: "",
			 payee_name_line1: payee,
			 destination_code: destinationcode,
			 mailing_info: mailinginfo,
			 street_name: streetname,
			 city:city,
			 state_province: stateprovince,
			 zip_postal_code: zippostalcode,
			 country: "US",
			 apt_number: aptnumber,
			 suite_number: suitenumber,
			 additional_line1: additionalline1,
			 additional_line2: "",
			 explanation_line1: expl1,
			 payor_account: payoraccount,
			 payor_first_name: payorfirstname,
			 payor_last_name: payorlastname,
			 client_id: this.state.client.id,
			 payment_type: payment_type,
			 description: description,
			 additional: additional,
		 }
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.addSummaryPayment(data)
			this.getClientPayments(payment_type);
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	addFee(amount,expl4){
		var url = API_URL + "/api/v1/new/fee/";
		var data = {
			client_id: this.state.client.id,
			amount: parseFloat(amount),
			description: expl4,
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			this.addSummaryPayment(data)
			this.getClient();
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	updateParent(){
		this.getClient();
		this.setState({key: Math.random()})
	}

	closeDetails(){
		this.getClient();
		this.setState({displayDetails: false})
	}

	redirect = () => {
		this.props.history.push("/true-link/" + this.state.client.id)
	}

	render() {
		if (this.state.client == null ){
			return ""
		}

		const dialogHeaderTrueLink = (
			<div>
				<h1>True Link</h1>
			</div>
		);

		const dialogHeaderNote = (
			<div>
			<h1>Add a New Note</h1>
			</div>
		);

		const dialogHeaderCaseNote = (
			<div>
			<h1>Add a New Case Note</h1>
			</div>
		);

		const dialogHeaderTransactions = (
			<div>
				<h1>Transactions</h1>
			</div>
		);

		const dialogHeaderSumary = (
			<div>
				<h1>Client Summary</h1>
			</div>
		);

		const dialogHeaderTransfer = (
			<div>
				<h1>Transfer Funds</h1>
			</div>
		);

		const dialogHeaderDetails = (
			<div>
				<h1>Client Details</h1>
			</div>
		);
		
		const dialogFooterNote = (
			<div style={{textAlign: "center"}}>
			<Button
				className="default-btn p-button-success"
				onClick={() => this.addNote() }
				label="Add"
			/>
			<Button
				className="default-btn p-button-danger"
				onClick={() => this.setState({displayNotes: false}) }
				label="Cancel"
			/>
			</div>
		);

		const dialogFooterCaseNote = (
			<div style={{textAlign: "center"}}>
			<Button
				className="default-btn p-button-success"
				onClick={() => this.addCaseNote() }
				label="Add"
			/>
			<Button
				className="default-btn p-button-danger"
				onClick={() => this.setState({displayCaseNotes: false}) }
				label="Cancel"
			/>
			</div>
		);

		var permissions = JSON.parse(sessionStorage.permissions)
		var role = sessionStorage.role

		return (
			<div className="p-grid">
				<Growl ref={(el) => this.growl = el} />
				<div className="p-col-12 client-info">
					<div className="card">
						<h1>{this.state.client.firstname} {this.state.client.lastname}</h1> <h2 className="trust">{this.state.client.trust.name}</h2>
						<p style={{whiteSpace: 'pre-line',marginTop: '0px'}}>
							{this.state.client.address1+"\n"}
							{this.state.client.address2 ? this.state.client.address2+"\n" : ""}
							{this.state.client.city}, {this.state.client.state} {this.state.client.zip}
							{"\n\n"}
							<strong>Account:</strong> {this.state.client.account+"\n"}
							{ role === 'admin' ?
								<strong>DB-ID: {this.state.client.id+"\n"}</strong>
								:
									""
							}
							<strong>Classification:</strong> {this.getClientClassification(this.state.client.classification_id)}
							{"\n"}<strong>Case Opened Date:</strong> {this.state.client.case_opened}
						</p>
						{/*<div className="p-grid info-buttons">*/}
						<div className="p-grid buttons">
							{ permissions.client_details === true ?
								<div className="p-col-4">
									<button className="p-button p-button-warning default-btn" onClick={() => this.setState({displayDetails: true})}>Details +</button>
								</div>
							:
								""
							}
							{this.state.client.truelink && permissions.true_link_view ?
								<div className="p-col-4">
									<button className="p-button p-button-warning default-btn" onClick={this.redirect}>True Link</button>
								</div>
								:
								""
							}
							<div className="p-col-4">
								<button className="p-button p-button-warning default-btn" onClick={() => this.setState({displayNotes: true})}>Notes +</button>
							</div>
						</div>
					</div>
				</div>

				{/*<div className="p-col-8 client-summary">*/}
				{/*	<div className="card">*/}
				{/*		<div className="p-grid">*/}
				{/*			<div className="p-col-3">*/}
				{/*				Current Balance*/}
				{/*			</div>*/}
				{/*			<div className="p-col-7">*/}
				{/*				<ProgressBar value={100} showValue={false}/>*/}
				{/*			</div>*/}
				{/*			<div className="p-col-2">*/}
				{/*				${this.state.client.balance}*/}
				{/*			</div>*/}
				{/*			<div className="p-col-3">*/}
				{/*				Monthly Payments*/}
				{/*			</div>*/}
				{/*			<div className="p-col-7">*/}
				{/*				<ProgressBar value={100} showValue={false}/>*/}
				{/*			</div>*/}
				{/*			<div className="p-col-2">*/}
				{/*				{this.state.client.monthly_spending}*/}
				{/*			</div>*/}
				{/*			<div className="p-col-3">*/}
				{/*				Pending Balance*/}
				{/*			</div>*/}
				{/*			<div className="p-col-7">*/}
				{/*				<ProgressBar value={100} showValue={false}/>*/}
				{/*			</div>*/}
				{/*			<div className="p-col-2">*/}
				{/*				{this.state.client.pending_balance}*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}

				{/*	<div className="p-grid">*/}
				{/*		<div className="p-col-3">*/}
				{/*			<div className="card">*/}
				{/*				{this.state.client.status === 'active' && permissions.welcome_packets === true ?*/}
				{/*					<div className="p-col-12">*/}
				{/*						<WelcomePackets client={this.state.client}/>*/}
				{/*					</div>*/}
				{/*				:*/}
				{/*					""*/}
				{/*				}*/}
				{/*				{this.state.client.status === 'active' ?*/}
				{/*					<div className="p-col-12">*/}
				{/*						<button className="p-button p-button-warning default-btn" onClick={() => this.setState({displayTransfer: true})}>Transfer Funds</button>*/}
				{/*					</div>*/}
				{/*				:*/}
				{/*					""*/}
				{/*				}*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className="p-col-3">*/}
				{/*			<div className="card">*/}
				{/*				<Upload client={this.state.client} getClient={this.getClient.bind(this)} location={this.props.location} paginator={this.state.deposits.pageinator} />		*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		<div className="p-col-6" style={{height:"398px"}}>*/}
				{/*			<div className="card"  style={{height:"100%"}}>*/}
				{/*				<h2>Documents</h2>*/}
				{/*				<div>{this.state.client.joinder ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Joinder Agreement (Draft)</div>*/}
				{/*				<div>{this.state.client.poa ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}POA (If Applicable)</div>*/}
				{/*				<div>{this.state.client.auth_contacts ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Authorized Contacts</div>*/}
				{/*				<div>{this.state.client.text_messaging ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Text Messaging</div>*/}
				{/*				<div>{this.state.client.proof_of_income ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Proof of Income</div>*/}
				{/*				<div>{this.state.client.spenddown ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Spenddown Letter (if MSD)</div>*/}
				{/*				<div>{this.state.client.medicaid_letter ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Medicaid Letter</div>*/}
				{/*				<div>{this.state.client.disability ? <i className="pi pi-check-circle green"></i> : <i className="pi pi-times-circle"></i>}Proof of Disability (If Over 65)</div>*/}
				{/*				<div id="all-documents">*/}
				{/*					<Documents client_id={this.state.client.id} client_path={"/documents/" + this.state.client.lastname +"_" + this.state.client.account+"/"} />*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}

				<div className="p-col-12">
					<div className="card">
						<h1>Additional Notes</h1>
						<AdditionalNotes ref={this.additionalNotes} client_id={this.state.client.id} />
					</div>
				</div>

				{this.state.client.status === 'active' ?
				<div>
					<TransactionsDeposit key={this.state.key1} title="Deposits" addDeposit={this.addDeposit} paginator={this.state.deposits.paginator} client={this.state.client} dataTableValue={this.state.deposits.data } classification={this.getClientClassification(this.state.client.classification_id)} files={this.state.files} update={this.updateParent} />
					<TransactionsFee key={this.state.key2} title="Fees" addFee={this.addFee} paymentType={3} dataTableValue={this.state.fees.data} paginator={this.state.fees.paginator} client={this.state.client} classification={this.getClientClassification(this.state.client.classification_id)} files={this.state.files} update={this.updateParent} />
					<Transactions key={this.state.key3} title="Recurring Payments with Deposit" paymentModel={"showPaymentModal"} addPayment={this.addPayment} paymentType={1} dataTableValue={this.state.paymentsWithDeposit.data} paginator={this.state.paymentsWithDeposit.paginator} client={this.state.client} classification={this.getClientClassification(this.state.client.classification_id)} files={this.state.files} update={this.updateParent}  formName="rpwd" />
					<Transactions key={this.state.key4} title="Recurring Payments with Invoice" paymentModel={"showPaymentModal"} addPayment={this.addPayment} paymentType={2} dataTableValue={this.state.paymentsWithInvoice.data} paginator={this.state.paymentsWithInvoice.paginator} client={this.state.client} classification={this.getClientClassification(this.state.client.classification_id)} files={this.state.files} update={this.updateParent} formName="rpwi" />
					<Transactions key={this.state.key5} title="Common Payment" paymentModel={"showPaymentModal"} addPayment={this.addPayment} dataTableValue={this.state.commonPayment.data} paymentType={4} paginator={this.state.commonPayment.paginator} client={this.state.client} classification={this.getClientClassification(this.state.client.classification_id)} files={this.state.files} update={this.updateParent} formName="common" style={{marginBottom:"90px"}} />
				</div>
				: 
					permissions.approve_documents === true || permissions.approve_for_board === true || permissions.scan_documents === true || permissions.final_approval === true ?
						<ClientApproval client={this.state.client} history={this.props.history}/>
					:
						""
				}	


				<div>
					<button className="note-button" onClick={() => this.setState({displaySummary: true})}><i className="pi pi-list"></i></button>
					{/*<button className="tranaction-button" onClick={() => this.setState({displayTransactions: true})}>Transactions</button>*/}
					<button className="case-notes-button" onClick={() => this.setState({displayCaseNotes: true})}>Case Notes</button>
				</div>
			
			{ /* TRUELINK DIALOG */ }
				<Dialog
			header={dialogHeaderTrueLink}
			visible={this.state.displayEdit}
			modal={true}
			width="200px"
			closable={true}
			className="new-user client-details"
			onHide={ () => this.setState({displayEdit: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
				<div className="p-col-12">
					
				</div>
				</div>
			</div>
			</Dialog>

			{ /* NOTES DIALOG */ }
				<Dialog
			header={dialogHeaderNote}
			footer={dialogFooterNote}
			visible={this.state.displayNotes}
			modal={true}
			width="200px"
			closable={true}
			className="new-user new-note"
			onHide={ () => this.setState({displayNotes: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
					<div className="p-col-12">
						<div>
								<form id="note-form">
									<label htmlFor="note">Description</label><br/>
									<InputTextarea id='note' rows={5} onChange={this.handleChange} required={true} autoResize={true}/>
								</form>
						</div>
					</div>
				</div>
			</div>
			</Dialog>

		{ /* CASE NOTES DIALOG */ }
				<Dialog
			header={dialogHeaderCaseNote}
			footer={dialogFooterCaseNote}
			visible={this.state.displayCaseNotes}
			modal={true}
			width="90%"
			closable={true}
			className="new-user case-notes"
			onHide={ () => this.setState({displayCaseNotes: false}) }
			ref={this.child}
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
					<div className="p-col-12">
						<div>
								<CaseNotes client={this.state.client} notes={this.state.case_notes}/>
								<form id="case-note-form">
									<label htmlFor="note">Case Note</label><br/>
									<InputTextarea id='casenote' rows={5} onChange={this.handleChange} required={true} autoResize={false}/>
								</form>
						</div>
					</div>
				</div>
			</div>
			</Dialog>


			{ /* TRANSACTION TABLE DIALOG */ }
				<Dialog
			header={dialogHeaderTransactions}
			visible={this.state.displayTransactions}
			modal={true}
			width="90%"
			closable={true}
			className="new-user transaction-table"
			dismissableMask={true}
			onHide={ () => this.setState({displayTransactions: false}) }
			>
			<div className="card card-w-title transaction" style={{paddingBottom:"0em !important"}}>
				<div className="p-grid">
					<div className="p-col-12">
						<TransactionsTable client={this.state.client} history={this.props.history}/>
					</div>
				</div>
			</div>
			</Dialog>

			{ /* DETAILS DIALOG */ }
			<Dialog
			header={dialogHeaderDetails}
			visible={this.state.displayDetails}
			footer={null}
			modal={true}
			width="400px"
			closable={true}
			className="new-user client-details"
			onHide={ () => this.setState({displayDetails: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
					<div className="p-col-12">
						<div>
							<Details client={this.state.client} close={this.closeDetails} />
						</div>
					</div>
				</div>
			</div>
			</Dialog>

		{ /* SUMMARY DIALOG */ }
			<Dialog
			header={dialogHeaderSumary}
			visible={this.state.displaySummary}
			footer={null}
			modal={true}
			width="90%"
			closable={true}
			className="new-user client-summary-dialog"
			dismissableMask={true}
			onHide={ () => this.setState({displaySummary: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-left">
					<div className="p-col-12">
						<div>
								<Summary key={this.state.key} client={this.state.client} history={this.props.history}/>
						</div>
					</div>
				</div>
			</div>
			</Dialog>

		{ /* TRANSFER DIALOG */ }
			<Dialog
			header={dialogHeaderTransfer}
			visible={this.state.displayTransfer}
			footer={null}
			modal={true}
			width="90%"
			closable={true}
			className="new-user client-summary-dialog"
			dismissableMask={true}
			onHide={ () => this.setState({displayTransfer: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-left">
					<div className="p-col-12">
						<div>
								<Transfer client={this.state.client} history={this.props.history}/>
						</div>
					</div>
				</div>
			</div>
			</Dialog>

		</div>
		);
	}
}
