import React, {Component} from 'react';
import {Messages} from 'primereact/messages';
import {Message} from 'primereact/message';
// import PropTypes from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
// import {Dropdown} from 'primereact/dropdown';
// import {InputTextarea} from 'primereact/inputtextarea';
import {ContextMenu} from 'primereact/contextmenu';
import {RadioButton} from 'primereact/radiobutton';
import {Checkbox} from 'primereact/checkbox';

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';

export class TransactionsDeposit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dataTableValue: "",
			dataTableSelection: "",
			contextSelection: null,
			showDepositModal: false,
			showPaymentModal: false,
			contextItems: [],
			depositType: "New Amount",
			recurringDeposit: false,
			menu: [],
			depositMethod: [
								{label: 'Check (Non Security)', value: 'Check (Non Security)'},
								{label: 'Chips Instruction', value: 'Chips Instruction'},
								{label: 'Credit Commercial Account', value: 'Credit Commercial Account'},
								{label: 'Fed Funds', value: 'Fed Funds'},
								{label: 'Nacha Instruction', value: 'Nacha Instruction'}
			],
			dropdownDepositMethod: "Check (Non Security)",
			destinationCodeDropdown: [
								{label: "Check (Non Security)", value: 106},
								{label: "Bill Pay", value: 0},
								{label: "Special Handling", value: 117},
								{label: "Send with Invoice", value: 107},
								{label: "FedEx", value: 114},
			],
			destinationCodeSelection: 106,
			approvedBy: [
				{label: "N/A", value: false},
				{label: "Manager", value: "manager"},
				{label: "Board", value: "board"}
			],
			approvedBySelection: false,
			additionl: "",
		}
		this.rowClassName = this.rowClassName.bind(this);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	componentWillMount(){
		this.setState({files: this.props.files})
		this.getExportFileCategories()
		var permissions = JSON.parse(sessionStorage.permissions)

		if ( permissions.payments_role === true ) {
			var menu = [
				{label: 'Export', icon: 'pi pi-fw pi-sign-in' },
				{label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (event) => this.editRow(event,this.state.contextSelection,this.props.title) },
				{separator:true },
				{label: 'Remove',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to remove this export?')) this.removeDeposit(this.state.contextSelection,event) } },
				{label: 'Delete',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(event,this.state.contextSelection,this.props.title) } },
			]
		} else {
			menu = []
		}
		this.setState({menu: menu})
	}

	componentDidMount(){
	}

	calculateFee(amount){
		var fee = 0.00;
		if (this.state.feeChecked === true){
			fee = this.state.newFee
		} else {
			switch(true){
				case amount <= 351.00:
					fee = 30.00
					break;
				case amount <= 501.00:
					fee = 40.00
					break;
				case amount <= 751.00:
					fee = 50.00
					break;
				case amount <= 1201.00:
					fee = 60.00
					break;
				case amount <= 1701.00:
					fee = 90.00
					break;
				case amount <= 2201.00:
					fee = 120.00
					break;
				case amount <= 2701.00:
					fee = 150.00
					break;
				case amount <= 3201.00:
					fee = 180.00
					break;
				case amount <= 3701.00:
					fee = 210.00
					break;
				case amount > 3701.00:
					fee = 240.00
					break;
				default:
					break;
			}
		}
		return fee
	}

	removeDeposit(content,event){
		// console.log(event.item)
		// console.log("CONTENT")
		// console.log(content)
		var file_id = content.export_file_ids[0];
		var data = {export_file: {
	    remove_object_id: content.id,
	  }}

		var url = API_URL + "/api/v1/export_files/" + file_id;
		axios.put(url, data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log("item deleted from export")
			}
		});
		this.props.update();
	}

	getExportFileCategories(){
		var url = API_URL + "/api/v1/export_file_categories";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var items = [];
				var items2 = []
				var that = this;
				res.data.export_file_categories.map(function(value,key){
					if( value.name === that.props.title || (value.name === "Management Approval" && that.props.title !== "Deposits")) {
						if(value.parent_id === null) {
							var files = that.build_list(value);
							var files2 = that.build_remove_list(value)
							var item = {label: value.name, items: files}
							var item2 = {label: value.name, items: files2}
							items.push(item);
							items2.push(item2)
						}
					} else if (value.name === "Disbursements" && that.props.title !== "Deposits") {
						if(value.parent_id === null) {
							files = that.build_list(value);
							files2 = that.build_remove_list(value)
							item = {label: value.name, items: files}
							item2 = {label: value.name, items: files2}
							items.push(item);
							items2.push(item2)
						}
					}
					return null;	
				});
				var menu = this.state.menu
				if ( menu[0] !== undefined ){
					menu[0].items = items
				}
				if ( menu[3] !== undefined ){
					menu[3].items = items2
				}
				this.setState({menu: menu});
			}
		});
	}

	editRow(event,row,category){
		if (category==="Deposits"){
			this.setState({Amount: row.amount,CheckNumber: row.check_number, updateDeposit: true, updateDepositRow: row.id ,showDepositModal: true })
		} else {
			this.setState({
				Amount: row.amount,
				City: row.city,
				Country: row.country,
				MailingInfo: row.mailinginfo,
				PayeeNameLine1: row.payee_name_line1,
				StateProvince: row.state_province,
				StreetName: row.street_name,
				ZipPostalCode: row.zip_postal_code,
				additional: row.additional,
				PayorAccount: row.payoraccount,
				PayorFirstName: row.PayorFirstName,
				PayorLastName: row.PayorLastName,
				SuiteNumber: row.SuiteNumber,
				description: row.description,
				AptNumber: row.AptNumber,
				AdditionalLine1: row.AdditionalLine1,
				showPaymentModal: true
			})
		}
	}

	deleteRow(event,row,category){
		if (category==="Deposits"){
			var url = API_URL + "/api/v1/deposits/" + row.id ;
			axios.delete(url,{
				headers: {
					Authorization: sessionStorage.getItem('jwtToken'),
				} 
			})
			.then(( res ) => {
				if (res) {
					console.log("Row Deleted")
				}
			});
		} else {
			url = API_URL + "/api/v1/payments/" + row.id ;
			axios.delete(url,{
				headers: {
					Authorization: sessionStorage.getItem('jwtToken'),
				} 
			})
			.then(( res ) => {
				if (res) {
					console.log("Row Deleted")
				}
			});
		}
		this.props.update();
	}

	build_list(category){
		if(this.props.files){
			var list = [];
			this.props.files.forEach((file,index) => {
				if ( file.category_id === category.id){
					list.push({label: file.name, command: (event) => this.export(this.state.contextSelection,category,event), file_id: file.id})
				}
			})
		}
		return list;
	}

	build_remove_list(category){
		if(this.props.files){
			var list = [];
			this.props.files.forEach((file,index) => {
				if ( file.category_id === category.id){
					list.push({label: file.name, command: (event) => this.removeDeposit(this.state.contextSelection,category,event), file_id: file.id})
				}
			})
		}
		return list;
	}

	getClientDeposit(){
		var url = API_URL + "/api/v1/client/depoits/" + this.state.client.id ;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var data = {}
				res.data.map(function(v,i){
					return Object.assign(data,v);
				});
				this.setState({properties: data });
				this.setState({displayEdit: true})
				this.properties = data
			}
		});
	}

	export(content,category,event){
		var payment_type = "";
		switch(category.name){
			case "Management Approval":
				payment_type = 'approval';
				break;
			case "Disbursements":
				payment_type = 'payment';
				break;
			case "Fees":
				payment_type = 'fee';
				break;
			case "Deposits":
				payment_type = 'deposit';
				break;
			default:
				//
		}
		var data = {
			export_file: {
				name: event.item.label,
				data_type: payment_type,
				connecting_object: {
					comment: content.additional,
					object_id: content.id
				}
			}
		}
		var file_id = event.item.file_id;
		var url = API_URL + "/api/v1/export_files/" + file_id;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.messages.show({severity: 'success', summary: 'SUCCESS!', detail: 'Deposit exported to file!'});
				console.log("added item to export")
				// If its a Deposit
				// we add it to the summary payments
				if(content){
					content.payment_type = 0
					content.payee_name_line1 = "Deposit"
					var url = API_URL + "/api/v1/summary_payments/"
					axios.post(url,content,{
						headers: {
							Authorization: sessionStorage.getItem('jwtToken'),
						} 
					})
					.then(( res ) => {
						if (res) {
							console.log("added item to summary payments")
						}
					});
				}
				// Then we update the client pending balance if it's not an approval file
				if(content){
					if(payment_type != 'approval' ){
						var url = API_URL + "/api/v1/clients/" + content.client_id + "/update_balance"
						data = {
							client: {
								amount: content.amount,
							}
						}
						axios.put(url,data,{
							headers: {
								Authorization: sessionStorage.getItem('jwtToken'),
							} 
						})
						.then(( res ) => {
							if (res) {
								console.log("client balance updated")
							}
						});
					}
				}
			}
		})
		.catch((error) =>  {
			if( this.messages ){
				this.messages.show({severity: 'error', summary: 'FAILED!', detail: 'Failed to export to file!'});
			}
		});

		

		// this.props.update();
	}

	addDeposit(){
		this.props.addDeposit(this.state.Amount,this.state.CheckNumber,this.state.recurringDeposit,this.calculateFee(this.state.Amount),this.state.feeExceptionExplanation)
		this.setState({showDepositModal: false})	
	}

	updateDeposit(){
		var data = {
			deposit: {
	    	amount: this.state.Amount,
	    	client_id: this.props.client.id,
	    	check_number: this.state.CheckNumber,
	    	fee: this.calculateFee(this.state.Amount),
	    	exception: this.state.feeExceptionExplanation,
	    	recurring: this.state.recurringDeposit,
  		}
		}
		var url = API_URL + "/api/v1/deposits/" + this.state.updateDepositRow;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				document.getElementById("Deposit-form").reset();
				this.setState({
					Amount: "",
					CheckNumber: "",
					showDepositModal: false,
					updateDeposit: false,
					updateDepositRow: null,
				});
			}
		});
		this.props.update();	
	}

	rowClassName(rowData) {
		let brand = rowData.export_files;
		var cName = ""
		if (brand.length > 0){
			cName = brand[brand.length -1].data_type === 'approval' ? {'p-highlight-a' : "a"} : {'p-highlight-b' : "b"} ;
		}
		return cName
	}

	resetDeposit(form){
		document.getElementById(form).reset();
		this.setState({
			Amount: "",
			CheckNumber: "",
			showDepositModal: true,
			updateDeposit: false,
			updateDepositRow: null,
		});
	}

	render() {
		const title = this.props.title === "Deposits" ? "Deposit" : this.props.title === "Fees" ? "Fee" : "Payment"
		const dialogHeader = (
			<div>
				{this.state.updateDeposit ?
					<h1>Update Deposit</h1>
					:
					<h1>New Deposit</h1>
				}
			</div>
			);

		const dialogFooter = (
			<div style={{textAlign: "center"}}>
				{this.state.updateDeposit ?
					<Button
						className="default-btn p-button-success"
						onClick={() => this.updateDeposit() }
						label="Update"
					/>
					:
					<Button
						className="default-btn p-button-success"
						onClick={() => this.addDeposit() }
						label="Create"
					/>
				}
				<Button
					className="default-btn p-button-danger"
					onClick={() => this.setState({showDepositModal: false}) }
					label="Cancel"
				/>
			</div>
		);
		var permissions = JSON.parse(sessionStorage.permissions)

		return (
			<div className="p-col-12 deposit-info">
				<Messages ref={(el) => this.messages = el}></Messages>
				<div className="card">
					<div className="deposit-header">
						<h1>{this.props.title}</h1>
						{ title === "Deposit" && permissions.add_deposit === true ?
								<button className="p-button p-button-warning default-btn" onClick={() => this.resetDeposit(title+"-form") }>Add {title}</button>
							:
								""
						}
					</div>
					{ title === "Deposit" ?
					<React.Fragment>
						
						<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
						
						<DataTable
							value={this.props.dataTableValue}
							selectionMode="single"
							resizableColumns={true}
							paginator={this.props.paginator}
							rows={5}
							selection={this.props.dataTableSelection}
							onSelectionChange={event => this.setState({dataTableSelection: event.value})}
							contextMenuSelection={this.state.contextSelection} 
							onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
							onContextMenu={e => this.cm.show(e.originalEvent)}
						>
							<Column field="amount" header="Amount" sortable={false} />
							<Column field="check_number" header="Check Number" sortable={false} />
						</DataTable>
					</React.Fragment>
					:
					<React.Fragment>
						<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
						
						<DataTable
							value={this.props.dataTableValue}
							selectionMode="single"
							resizableColumns={true}
							paginator={this.props.paginator}
							rows={5}
							autoLayout={true}
							selection={this.props.dataTableSelection}
							onSelectionChange={event => this.setState({dataTableSelection: event.value})}
							rowClassName={this.rowClassName}
							contextMenuSelection={this.state.contextSelection} 
							onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
							onContextMenu={e => this.cm.show(e.originalEvent)}
						>
							<Column field="amount" header="Amount" sortable={false} />
							<Column field="payee_name_line1" header="Payee Name" sortable={false} />
							<Column field="destination_code" header="Destination Code" sortable={false} />
							<Column field="mailing_info" header="Mailing Info" sortable={false} />
							<Column field="street_name" header="Street Name" sortable={false} />
							<Column field="city" header="City" sortable={false} />
							<Column field="state_province" header="State/Province" sortable={false} />
							<Column field="zip_postal_code" header="Zip/Postal Code" sortable={false} />
							<Column field="description" header="Description" sortable={false} />
						</DataTable>
					</React.Fragment>
					}
				</div>
				{ /* DEPOSIT DIALOG */ }
				<Dialog
					header={dialogHeader}
					footer={dialogFooter}
					visible={this.state.showDepositModal}
					modal={true}
					width="200px"
					closable={true}
					className="new-user"
					onHide={ () => this.setState({showDepositModal: false}) }
				>
					<div className="card card-w-title">
						<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
							<div className="p-col-12">
								<div>
									<form id={title + "-form"}>
										{this.state.updateDeposit ?
											""
											:
											<div className="p-grid" style={{width:'100%',marginBottom:'10px'}}>
												<div className="p-col-4">
													<RadioButton inputId="rb1" name="deposit-type" value="Monthly Spenddown" onChange={(e) => this.setState({depositType: e.value})} checked={this.state.depositType === 'Monthly Spenddown'} />
													<label htmlFor="rb1" className="p-radiobutton-label" style={{display:"block"}}>Monthly Spenddown</label>
												</div>
												<div className="p-col-4">
													<RadioButton inputId="rb2" name="deposit-type" value="Last Deposit" onChange={(e) => this.setState({depositType: e.value})} checked={this.state.depositType === 'Last Deposit'} />
													<label htmlFor="rb2" className="p-radiobutton-label" style={{display:"block"}}>Last<br />Deposit</label>
												</div>
												<div className="p-col-4">
													<RadioButton inputId="rb3" name="deposit-type" value="New Amount" onChange={(e) => this.setState({depositType: e.value})} checked={this.state.depositType === 'New Amount'} />
													<label htmlFor="rb3" className="p-radiobutton-label" style={{display:"block"}}>New<br />Amount</label>
												</div>
											</div>
										}
										<span className="p-float-label">
											<InputText id="Amount" onChange={this.handleChange} value={this.state.Amount ? this.state.Amount : "" }/>
											<label htmlFor="Amount">Amount</label>
										</span>
										<span className="p-float-label">
											<InputText id="CheckNumber" onChange={this.handleChange} value={this.state.CheckNumber ? this.state.CheckNumber : "" } />
											<label htmlFor="CheckNumber">Check Number</label>
										</span>
										<div className="p-grid" style={{width:'100%',marginBottom:'10px'}}>
											<div className="p-col-12" style={{marginTop: "10px"}}>
												Fee Exception? 
												<Checkbox style={{marginLeft: "10px"}} checked={this.state.feeChecked} onChange={e => this.setState({feeChecked: e.checked})} />
												{this.state.feeChecked ? 
													<React.Fragment>
														<InputText id="newFee" onChange={this.handleChange} placeholder="New Fee"/>
														<InputText id="feeExceptionExplanation" onChange={this.handleChange} placeholder="Fee Exception Explanation" />
													</React.Fragment>
													:
													""
												}
											</div>
											<div className="p-col-12" style={{marginTop: "10px"}}>
												Recurring? 
												<Checkbox style={{marginLeft: "10px"}} checked={this.state.recurringDeposit} onChange={e => this.setState({recurringDeposit: e.checked})} />
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}