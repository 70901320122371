import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog} from "primereact/dialog";
// import {Button} from 'primereact/button';

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';


export class Documents extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			display: false,
		}
		this.getClientDocuments = this.getClientDocuments.bind(this);
		this.buildDocumentList = this.buildDocumentList.bind(this);
	}

	UNSAFE_componentWillMount(){
		this.getClientDocuments();
	}

	getClientDocuments(){
		var url = API_URL + "/api/v1/clients/" + this.props.client_id + "/documents";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				if (res.data.length === 0) {
					this.setState({documents: [] });
				} else {
					this.setState({documents: res.data });	
				}
			}
		});
	}

	buildDocumentList(){
		var that = this
		if ( this.state.documents ) {
			var docs = this.state.documents.map(function(value,key){
				return (
					<React.Fragment key={key}>
						<a href = {API_URL + "/api/v1/document/" + that.props.client_id + "/" + value} target = "_blank">{value}</a><br />
					</React.Fragment>
				);
			});
			return docs;
		} else {
			return ""
		}
	}

	render(){
		const dialogHeader = (
			<div>
				<h1>All Documents</h1>
			</div>
		);

		const documents = this.buildDocumentList();
		return (
			<React.Fragment>
				<button className="p-button p-button-warning default-btn" onClick={() => this.setState({display: true})}>View All Documents</button>
				<Dialog
					header={dialogHeader}
					visible={this.state.display}
					modal={true}
					dismissableMask={true}
					closable={true}
					className="documents-dialog"
					onHide={ () => this.setState({display: false}) }
				>
					<div className="card card-w-title">
						<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
							<div className="p-col-12 textAlignLeft">
								{documents}
								<button className="p-button p-button-warning default-btn" onClick={this.getClientDocuments}>Refresh Document List</button>
							</div>
						</div>
					</div>
				</Dialog>
			</React.Fragment>
		);
	}
}
