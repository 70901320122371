// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .truelink .p-datatable .p-datatable-tbody > tr.p-highlight {
	background-color: inherit !important;
	color: black;
}

body .truelink .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
	background-color: inherit !important;
	color: black;	
}

.p-float-label .p-inputwrapper-filled ~ label {
  top: 0.7em !important;
}

body .p-dialog.true-link-dialog {
	width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/components/truelink.css"],"names":[],"mappings":"AAAA;CACC,oCAAoC;CACpC,YAAY;AACb;;AAEA;CACC,oCAAoC;CACpC,YAAY;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;CACC,YAAY;AACb","sourcesContent":["body .truelink .p-datatable .p-datatable-tbody > tr.p-highlight {\n\tbackground-color: inherit !important;\n\tcolor: black;\n}\n\nbody .truelink .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {\n\tbackground-color: inherit !important;\n\tcolor: black;\t\n}\n\n.p-float-label .p-inputwrapper-filled ~ label {\n  top: 0.7em !important;\n}\n\nbody .p-dialog.true-link-dialog {\n\twidth: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
