// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: #ffba01;
  border-radius: 5px;
}

.Progress {
  background-color: #ffba01;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/progress.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".ProgressBar {\n  width: 100%;\n  height: 8px;\n  background-color: #ffba01;\n  border-radius: 5px;\n}\n\n.Progress {\n  background-color: #ffba01;\n  height: 100%;\n  margin: 0;\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
