import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InputText} from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {InputMask} from 'primereact/inputmask';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';

import {API_URL} from '../../helpers/api_helpers'
import {stringToDate} from '../../helpers/formats'
import './new-client.css'

import axios, {isCancel, AxiosError} from 'axios';

export class NewClient extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	createClient(){
		const history = this.props.history;
		if (this.state.birthdate) {
			var bday = stringToDate(this.state.birthdate,"mm/dd/yyyy","/")
			this.setState({birthdate: bday })
			console.log("CHECKING")
			console.log(this.state)
		}
		var url = API_URL + "/api/v1/clients" ;
		var data = this.state
		if ( this.state.birthdate) {
			data.birthdate = bday
		}
		data.trust_id = this.props.trust.id
		data.balance = 0.0

		
		console.log(data)
		axios.post(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				history.push("/"+this.props.trust.name.replace(" ","-").toLowerCase() + "/pending-clients")
			}
		});
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};
	
	getclassifications() {
		// const history = this.props.history;
		var url = API_URL + "/api/v1/classifications";
		axios.get(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(({ data }) => {
				var dataSet = [];
				console.log(data);
				data.classifications.map(function(v,i){
					// console.log(v);
				 return dataSet.push({label: v.name, value: v.id});
				});
				this.setState({
					classifications: dataSet,
				});
			});
	}

	componentWillMount(){
		this.getclassifications();
	}

	render() {
		return (
			<div className="p-grid">
				<div className="p-col-8 client-info">
					<div className="card">
						<h1>New Client</h1>
						<div className="p-grid">
							<div className="p-col-6">
								<div>
									<label htmlFor="firstname">First Name</label><br/>
									<InputText id='firstname' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="lastname">Last Name</label><br/>
									<InputText id='lastname' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="address1">Address Line 1</label><br/>
									<InputText id='address1' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="address2">Address Line 2</label><br/>
									<InputText id='address2' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="city">City</label><br/>
									<InputText id='city' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="state">State</label><br/>
									<InputText id='state' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="zip">Zip</label><br/>
									<InputText id='zip' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="county">County</label><br/>
									<InputText id='county' onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="differentMailing">Different Mailing Address?</label><br/>
									<InputSwitch id='differentMailing' onChange={this.handleChange} checked={this.state.differentMailing}/>
								</div>
								{this.state.differentMailing ?
									<React.Fragment>
										<div>
											<label htmlFor="mailing_address1">Mailing Address Line 1</label><br/>
											<InputText id='mailing_address1' onChange={this.handleChange} required={true}/>
										</div>
										<div>
											<label htmlFor="mailing_address2">Mailing Address Line 2</label><br/>
											<InputText id='mailing_address2' onChange={this.handleChange} required={true}/>
										</div>
										<div>
											<label htmlFor="mailing_city">Mailing City</label><br/>
											<InputText id='mailing_city' onChange={this.handleChange} required={true}/>
										</div>
										<div>
											<label htmlFor="mailing_state">Mailing State</label><br/>
											<InputText id='mailing_state' onChange={this.handleChange} required={true}/>
										</div>
										<div>
											<label htmlFor="mailing_zip">Mailing Zip</label><br/>
											<InputText id='mailing_zip' onChange={this.handleChange} required={true}/>
										</div>
									</React.Fragment>
								:
									""
								}
							</div>
							<div className="p-col-6">
								<div>
									<label htmlFor="social_security_number">Social Security Number</label><br/>
									<InputMask mask="999-99-9999" id="social_security_number" onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="birthdate">Date of Birth</label><br/>
									<InputMask mask="99/99/9999" id="birthdate" onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="phone">Phone</label><br/>
									<InputText id="phone" onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="cin">Medicaid CIN#</label><br/>
									<InputText id="cin" onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="primary_contact_relationship">Primary Contact</label><br/>
									<InputText id="primary_contact_relationship" onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="primary_contact_phone">Primary Contact Phone</label><br/>
									<InputText id="primary_contact_phone" onChange={this.handleChange} required={true}/>
								</div>
								<div>
									<label htmlFor="zip">Classification</label><br/>
									<Dropdown id="classification_id" name="classification_id" options={this.state.classifications} value={this.state.classification_id} onChange={event => this.setState({classification_id: event.value})} autoWidth={true} placeholder="Select a Classification" />
								</div>
								<div>
									<label htmlFor="disability">Disability?</label><br/>
									<InputSwitch id='disability' onChange={this.handleChange} checked={this.state.disability}/>
								</div>
								{this.state.disability ?
									<div>
										<label htmlFor="disability">Nature of Disablity</label><br/>
										<InputText id="disability_info" onChange={this.handleChange} />
									</div>
								:
									""
								}
								<div>
									<label htmlFor="burial_account">Burial Account?</label><br/>
									<InputSwitch id='burial_account' onChange={this.handleChange} checked={this.state.burial_account}/>
								</div>
								<div>
									<label htmlFor="ssi_beneficiary">SSI Benificiary?</label><br/>
									<InputSwitch id='ssi_beneficiary' onChange={this.handleChange} checked={this.state.ssi_beneficiary}/>
								</div>
								<div>
									<label htmlFor="truelink">True Link?</label><br/>
									<InputSwitch id='truelink' onChange={this.handleChange} checked={this.state.truelink}/>
								</div>
							</div>
						</div>
						<div style={{textAlign: "center",marginTop:"20px"}}>
							<Button className="default-btn p-button-success" onClick={() => this.createClient() } label="Create" />
						</div> 
					</div>
				</div>
			</div>
		);
	}
}