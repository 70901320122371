// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category, .sub-category {
  margin-left: 20px;
}

.sub-category .title {
	margin-left: -20px;
}

.p-calendar {
	margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/views/Exports/view.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".category, .sub-category {\n  margin-left: 20px;\n}\n\n.sub-category .title {\n\tmargin-left: -20px;\n}\n\n.p-calendar {\n\tmargin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
