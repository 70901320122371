import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {ContextMenu} from 'primereact/contextmenu';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {formatMoney} from '../helpers/formats'

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';


export class TransactionsTable extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			contextSelection: null,
			contextItems: [],
			menu: [
				{label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (event) => this.editRow(event,this.state.contextSelection,this.props.title) },
				{separator:true },
				{label: 'Delete',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(event,this.state.contextSelection,this.props.title) } },
			]
		}
		this.goTo = this.goTo.bind(this)
	}

	getTransactions(){
		var url = API_URL + "/api/v1/transactions?client_id="+this.props.client.id ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.setState({transactions: res.data.transactions });
			}
		});
	}

	UNSAFE_componentWillMount(){
		this.getTransactions()
	}

	amountTemplate(rowData){
		return formatMoney(rowData.amount)
	}

	balanceTemplate(rowData){
		return formatMoney(rowData.balance)
	}

	goTo(){
		this.props.history.push({
			pathname: '/edittransactions',
			state: { id: this.props.client.id, client: this.props.client  }
		})
	}

	renderButton(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.edit_transactions === true ) {
			return (
				<div style={{textAlign: "center",maxWidth: "290px"}}>
					<Button
						className="default-btn p-button-success"
						onClick={this.goTo}
						href = {'/edittransactions/'+this.props.client.id}
						label="Edit Transactions"
					/>
				</div>
			)
		} else {
			return ""
		}
	}

	render(){

		var header = <div style={{'textAlign':'left'}}>
			<i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
			<InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50" style={{width:"unset"}}/>
		</div>;

		const dialogFooter = this.renderButton()

		return (
			<div>
					<ContextMenu className={"transactions-context-menu"} style={{zIndex: 3000}} model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
					<DataTable
						value={this.state.transactions}
						selectionMode="single"
						scrollable={true}
						scrollHeight="60vh"
						resizableColumns={true}
						columnResizeMode="fit"
						header={header}
						globalFilter={this.state.globalFilter}
					>
						<Column field="transaction_date" header="Date" sortable={true} style={{width:"10%"}} />
						<Column field="transaction_type" header="Type" sortable={true} style={{width:"10%"}} />
						<Column field="name" header="Name" sortable={true} style={{width:"30%"}} />
						<Column field="memo" header="Memo" sortable={true}  style={{width:"30%"}}/>
						<Column field="amount" header="Amount" body={this.amountTemplate} sortable={true} style={{width:"10%"}} />
						<Column field="balance" header="Balance" body={this.balanceTemplate} sortable={true} style={{width:"10%"}} />
					</DataTable>
					{dialogFooter}
			</div>
		);
	}
}
