import React, {Component} from 'react'
import {Messages} from 'primereact/messages';
import {Message} from 'primereact/message';
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import {InputText} from "primereact/inputtext"
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {ContextMenu} from 'primereact/contextmenu'
import {Calendar} from 'primereact/calendar';
import {Growl} from 'primereact/growl';

import {formatDate,formatMoney} from '../helpers/formats'
import {API_URL} from '../helpers/api_helpers'

import axios from 'axios';

export class CheckLog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			clients: [],
			contextSelection: null,
			contextItems: [],
			selected_client: "",
			check_number: "",
			amount: "",
			received_date: new Date(),
			checkdate: "",
			firstname: "",
			lastname: "",
			account: "",
			isExportDisabled: false,
			menu: [
				{label: 'Clear', icon: 'pi pi-fw pi-eject', command: (event) => this.clearCheck(event,this.state.contextSelection) },
			],
		}
		this.clientsTemplate = this.clientsTemplate.bind(this)
		this.inputTextEditor = this.inputTextEditor.bind(this)
		this.inputCalendarEditor = this.inputCalendarEditor.bind(this)
		this.amountEditor = this.amountEditor.bind(this)
		this.checkNumberEditor = this.checkNumberEditor.bind(this)
		this.receivedDateEditor = this.receivedDateEditor.bind(this)
		this.checkDateEditor = this.checkDateEditor.bind(this)
		this.updateCheck = this.updateCheck.bind(this)
		this.updateButtonTemplate = this.updateButtonTemplate.bind(this)
	}

	componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.check_log_view === false || permissions.check_log_view === undefined ) {
			window.location = "/"
		}

		this.getClients()
		this.getCheckLog()
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	getClients(){
		var url = API_URL + "/api/v1/clients"
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var clients = res.data.clients.map((e) => {
					return {label: e.account + ": " + e.firstname + " " + e.lastname, value: e.id}
				})

				this.setState({clients: clients, client_list: res.data.clients });
			}
		})
	}

	getCheckLog(){
		const url = API_URL + "/api/v1/check_logs?cleared=false"
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.setState({data: res.data.check_logs});
			}
		})
	}

	addCheck(){
		var url = API_URL + "/api/v1/check_logs/"
		var errors = false
		if ( this.state.selected_client == ""){
			errors = true
			this.showError("Client can't be blank.")
		}
		if ( this.state.check_number == ""){
			errors = true
			this.showError("Check Number can't be blank.")
		}
		if ( this.state.amount == ""){
			errors = true
			this.showError("Amount can't be blank.")
		}
		if ( this.state.received_date == ""){
			errors = true
			this.showError("Received Date can't be blank.")
		}
		if ( this.state.checkdate == ""){
			errors = true
			this.showError("Check Date can't be blank.")
		}
		if ( errors === true){
			return
		}
		var data = {
			client_id: this.state.selected_client,
			check_number: this.state.check_number,
			amount: this.state.amount,
			received_date: this.state.received_date,
			checkdate: this.state.checkdate,
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			// console.log(res)
			this.setState({
				selected_client: "",
				check_number: "",
				amount: "",
				received_date: new Date(),
				checkdate: ""
			})
			this.messages.show({severity: 'success', summary: 'SUCCESS!', detail: 'Check added to Check Log!'});
			this.getCheckLog()
		})
		.catch( ( error ) => {
			this.messages.show({severity: 'error', summary: 'FAILED!', detail: 'Failed to add to Check Log!'});
			console.log(error)
		})
	}

	clearCheck(event,content){
		const url = API_URL + "/api/v1/check_logs/" + content.id
		const data = {
			cleared: true,
		}
		axios.put(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			console.log(res)
			this.getCheckLog()
		})
		.catch( ( error ) => {
			console.log(error)
		})
	}

	exportChecks = () => {
		console.log('exportChecks');

		this.setState((prevState) => ({
			isExportDisabled: true,
		}));

		const url = API_URL + "/api/v1/check_logs.xlsx?cleared=false"
		axios.get(url,{
			responseType: 'blob',
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(( res ) => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'check_logs.xlsx');
				document.body.appendChild(link);
				link.click();
				link.remove();
				window.URL.revokeObjectURL(url);
			})
			.finally(() => {
				console.log('exportChecks finalization');
				this.setState((prevState) => ({
					isExportDisabled: false,
				}));
			})
	}

	updateCheck(data){
		const url = API_URL + "/api/v1/check_logs/" + data.id
		const data_out = {
			amount: data.amount,
			check_number: data.check_number,
			received_date: data.received_date,
			checkdate: data.checkdate,
		}
		axios.put(url, data_out, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			console.log(res)
			this.getCheckLog()
		})
		.catch( ( error ) => {
			console.log(error)
		})
	}

	clientsTemplate(rowData, column){
		let label = ""
		this.state.clients.forEach((c) => {
			if (rowData.client_id === c.value){
				label = c.label
			}
		})
		return label
	}

	updateButtonTemplate(rowData, column){
		return (
			<div className="p-grid" style={{width: "100%"}}>
				{/*<div className="p-col-12">*/}
				{/*	<Button className="p-button-success" onClick={() => this.updateCheck(rowData)} label="Update"/>*/}
				{/*</div>*/}
				<div className="p-col-12">
					<Button className="p-button-success pl1" onClick={() => { if (window.confirm('Are you sure you want to clear the check log?')) this.clearCheck({}, rowData)}} label="Clear"/>
				</div>
			</div>
		)
	}

	/* Cell Editing */
	onEditorValueChange(props, value) {
		let updatedCheckLogs = [...props.value];
		updatedCheckLogs[props.rowIndex][props.field] = value.target.value;
		this.setState({data: updatedCheckLogs});
	}

	/* Calendar Editing */
	onCalendarValueChange(props, value,field) {
		let updatedCheckLogs = [...props.value];
		updatedCheckLogs[props.rowIndex][props.field] = formatDate(value.target.value,"/");
		// this.setState({"{props.rowData[field]": value})
		this.setState({data: updatedCheckLogs});
	}

	inputTextEditor(props, field) {
		return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e)} />;
  }

  inputCalendarEditor(props, field) {
  	var date = new Date(props.rowData[field])
  	return <Calendar style={{marginTop:"0px"}} value={date} onChange={(e) => this.onCalendarValueChange(props, e,field)}></Calendar>
  }
    
	amountEditor(props) {
		return this.inputTextEditor(props, 'amount');
	}

	checkNumberEditor(props) {
		return this.inputTextEditor(props, 'check_number');
	}

	receivedDateEditor(props) {
		return this.inputCalendarEditor(props, 'received_date')
	}

	checkDateEditor(props) {
		return this.inputCalendarEditor(props,'checkdate')
	}

	showError(message) {
		let msg = {severity: 'error', summary: message, detail: 'Validation failed'};
		this.growl.show(msg);
	}

	export = () => {
		this.dt.exportCSV();
	}

	amountTemplate(rowData){
		return 
	}

	render() {
		const buttonStyle = {
			opacity: this.state.isExportDisabled ? 0.5 : 1,
			cursor: this.state.isExportDisabled ? 'not-allowed' : 'pointer',
			pointerEvents: this.state.isExportDisabled ? 'none' : 'auto'
		}

		const header =
			<div className="p-grid" style={{width: "100%"}}>
				<div style={{'textAlign': 'left'}} className="p-col-9">
					<i className="pi pi-search" style={{margin: '4px 4px 0 0'}}></i>
					<InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})}
							   placeholder="Global Search" size="50" style={{width: "unset"}}/>
				</div>
				<div style={{'textAlign': 'right'}} className="p-col-3">
					<Button className="p-button-success" style={buttonStyle} disabled={this.state.isExportDisabled} onClick={this.exportChecks} label={this.state.isExportDisabled ? 'Exporting...' : 'Export All'}/>
					<span>&nbsp;&nbsp;</span>
					<Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
				</div>
			</div>;
		const permissions = JSON.parse(sessionStorage.permissions)

		if (permissions.check_log_add === true) {
			return (
				<div className="p-grid" style={{width: "100%"}}>
					<Growl ref={(el) => this.growl = el}/>
					<div className="p-col-12 client-info">
						<div className="card" style={{display:"flex"}}>
							<div className="p-grid" style={{width:"100%"}}>
								<div className="p-col-12">
									<h1>Check Log</h1>
								</div>
								<div className="p-col-4">
									<Dropdown value={this.state.selected_client} options={this.state.clients} style={{width:"380px"}} onChange={(e) => {console.log(e);this.setState({selected_client: e.value})}} filter={true} filterPlaceholder="Select Client" filterBy="account" placeholder="Select a Client"/>
								</div>
								<div className="p-col-2">
									<InputText id='amount' value={this.state.amount} onChange={this.handleChange} placeholder="Amount" />
								</div>
								<div className="p-col-2">
									<InputText id='check_number' value={this.state.check_number} onChange={this.handleChange} placeholder="Check Number" />
								</div>
								<div className="p-col-1">
									<Calendar id='received_date' style={{marginTop:"0px"}} value={this.state.received_date ? this.state.received_date : ""} onChange={this.handleChange } placeholder="Date Received"></Calendar>
								</div>
								<div className="p-col-1">
									<Calendar id='checkdate' style={{marginTop:"0px"}} value={this.state.checkdate ? this.state.checkdate : ""} onChange={this.handleChange } placeholder="Check Date"></Calendar>
								</div>
								<div className="p-col-2">
									<Button className="p-button-success" onClick={() => this.addCheck() } label="Add" />
								</div>
							</div>
						</div>
					</div>
					<div className="p-col-12">
						<Messages ref={(el) => this.messages = el}></Messages>
						<div className="card">
							<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
							<DataTable
								editable={true}
								contextMenuSelection={this.state.contextSelection}
								onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
								onContextMenu={e => this.cm.show(e.originalEvent)}
								header={header}
								globalFilter={this.state.globalFilter}
								ref={(el) => { this.dt = el; }}
								value={this.state.data}>
									<Column field="account" header="Account" sortable={true}/>
									<Column field="firstname" header="Firstname" sortable={true}/>
									<Column field="lastname" header="Lastname" sortable={true}/>
									<Column editor={this.amountEditor} field="amount" header="Amount" sortable={true}/>
									<Column editor={this.checkNumberEditor} field="check_number" header="Check Number" sortable={true}/>
									<Column editor={this.receivedDateEditor} field="received_date" header="Date Received" sortable={true}/>
									<Column editor={this.checkDateEditor} field="checkdate" header="Check Date" sortable={true}/>
									<Column field="update" body={this.updateButtonTemplate}/>
								</DataTable>
						</div>
					</div>
				</div>
			);
		} else {
			return (
			<div className="p-grid" style={{width:"100%"}}>
				<Growl ref={(el) => this.growl = el} />
				<div className="p-col-12 client-info">
					<div className="card" style={{display:"flex"}}>
						<div className="p-grid" style={{width:"100%"}}>
							<div className="p-col-12">
								<h1>Check Log</h1>
							</div>
						</div>
					</div>
				</div>
				<div className="p-col-12">
					<Messages ref={(el) => this.messages = el}></Messages>
					<div className="card">
						<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
						<DataTable
							editable={true}
							contextMenuSelection={this.state.contextSelection}
							onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
							onContextMenu={e => this.cm.show(e.originalEvent)}
							header={header}
							globalFilter={this.state.globalFilter}
							value={this.state.data}>
								<Column field="account" header="Account" sortable={true}/>
								<Column field="firstname" header="Firstname" sortable={true}/>
								<Column field="lastname" header="Lastname" sortable={true}/>
								<Column field="amount" header="Amount" sortable={true}/>
								<Column field="check_number" header="Check Number" sortable={true}/>
								<Column field="received_date" header="Date Received" sortable={true}/>
								<Column field="checkdate" header="Check Date" sortable={true}/>
							</DataTable>
					</div>
				</div>
			</div>
		);
		}
	}
}