import React, {Component} from 'react';
import {Checkbox} from 'primereact/checkbox'
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {InputText} from "primereact/inputtext";
// import {Password} from 'primereact/password';
import {Dropdown} from 'primereact/dropdown';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
// import {DataView, DataViewLayoutOptions} from 'primereact/dataview';
import {Column} from 'primereact/column';
import {Growl} from 'primereact/growl';
import {API_URL} from '../../helpers/api_helpers'
import './new.css';

import axios, {isCancel, AxiosError} from 'axios';

function validate(name, email, password, role) {
  // true means invalid, so our conditions got reversed
  return {
    email: email.length === 0,
    password: password.length === 0,
    name: name.length === 0,
  };
}


class Buttons extends Component {
	
	render(){
		
		return (
			<div className="crud-btns new-user">
				<button className="p-button p-button-rounded" onClick={this.props.newUser}>New</button>
				{ this.props.selection != null &&
					<React.Fragment>
						<button className="p-button p-button-rounded p-button-warning" onClick={this.props.editUser}>Edit</button>
						<button className="p-button p-button-rounded p-button-danger" onClick={this.props.handleDelete}>Delete</button>
					</React.Fragment>
				}
			</div>
		)
	}
}

export class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayNew: false,
			displayEdit: false,
			users: [],
			dataTableSelection: null,
      email: "",
      password: "",
      name: "",
      permissions: {
      	edit_pending_clients: "",
				classifictions: "",
				add_new_clients: "",
				approve_documents: "",
				approve_for_board: "",
				closed_clients: "",
				import: "",
				export: "",
				allocations: "",
				true_link_view: "",
				true_link_edit: "",
				edit_transactions: "",
				client_details: "",
				add_deposit: "",
				add_fee: "",
				add_payment: "",
				restrict_one_time_change: "",
				payments_role: "",
				check_log_view: "",
				check_log_add: "",
				add_user: "",
				scan_documents: "",
				final_approval: "",
				view_closed: "",
				welcome_packets: "",
      },
      select_all: false,
      select_none: false,
      touched: {
        email: false,
        password: false,
        name: false,
      }
		};
		this.handleDelete = this.handleDelete.bind(this);
		this.selectUser = this.selectUser.bind(this);
		this.editUser = this.editUser.bind(this);
		this.createUser = this.createUser.bind(this);
		this.getSelected = this.getSelected.bind(this);
		this.showError = this.showError.bind(this);
		this.select_all = this.select_all.bind(this)
		this.select_none = this.select_none.bind(this)
	}

	componentDidMount() {
		this.getUsers();
	}

	componentWillMount() {
		var permissions = JSON.parse(sessionStorage.permissions)
		if (permissions.add_user !== true) {
			window.location = "/"
		}
	}

	getUsers() {
		// const history = this.props.history;
		var url = API_URL + "/api/v1/users";
		axios.get(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(({ data }) => {
				const { users } = data;
				this.setState({
					users: users,
				});
			});
	}

	selectUser(event,rowData) {
		this.state.dataTableSelection === event.value ? this.setState({dataTableSelection: null, role:"",name:"",email:""}) : this.setState({
			dataTableSelection: event.value,
			name:event.value.name,
			email:event.value.email,
			permissions:event.value.permissions})
	}

	getSelected(){
		return this.state.dataTableSelection
	}

	createUser(){
		var url = API_URL + "/api/v1/auth/register";
		axios.post(url, {
			user: {
				name: this.state.name,
				email: this.state.email,
				password: this.state.password,
				password_confirmation: this.state.password_confirmation,
				permissions: this.state.permissions,
			}
		},{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      }}
		)
		.then(( res ) => {
			if (res.status !== 215) {
				this.getUsers();
				this.setState({displayNew: false});
			} else {
				this.showError(res.data.email);
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	editUser(){
		var url = API_URL + "/api/v1/users/"+this.state.dataTableSelection.id;
		var data = {
			id: this.state.dataTableSelection.id,
			name: this.state.name,
			email: this.state.email,
			permissions: this.state.permissions,
		}
		if (this.state.password !== ""){
			data.password = this.state.password
		}
		axios.put(url, data, {headers: {Authorization: sessionStorage.getItem('jwtToken')} } )
		.then(( res ) => {
			if (res.status !== 215) {
				this.getUsers();
				this.setState({displayEdit: false});
			} else {
				this.showError(res.data.email);
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	handleDelete() {
		console.log("handleDelete")
		const { users } = this.state;
		var url = API_URL + "/api/v1/users/"+this.state.dataTableSelection.id;
		axios.delete(url, {headers: {Authorization: sessionStorage.getItem('jwtToken')} }
		)
		.then((res) => {
			this.setState({
				users: users.filter(u => u.id !== this.state.dataTableSelection.id),
				dataTableSelection: '',
			});
		})
	}

	handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handlePermissions = event => {
  	console.log(event)
  	var permissions = this.state.permissions
    this.setState({
      permissions:{...permissions, [event.target.id]: event.target.checked}
    });
  };

  handleBlur = field => evt => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  };

  select_all = evert => {
  	this.setState({
  		select_all: true,
  		select_none: false,
  		permissions: {
      	edit_pending_clients: true,
				classifictions: true,
				add_new_clients: true,
				approve_documents: true,
				approve_for_board: true,
				closed_clients: true,
				import: true,
				export: true,
				allocations: true,
				true_link_view: true,
				true_link_edit: true,
				edit_transactions: true,
				client_details: true,
				add_deposit: true,
				add_fee: true,
				add_payment: true,
				restrict_one_time_change: true,
				payments_role: true,
				check_log_view: true,
				check_log_add: true,
				add_user: true,
				scan_documents: true,
				final_approval: true,
				view_closed: true,
				welcome_packets: true,
      }
  	})
  }

  select_none = evert => {
		this.setState({
			select_all: false,
			select_none: true,
			permissions: {
				edit_pending_clients: false,
				classifictions: false,
				add_new_clients: false,
				approve_documents: false,
				approve_for_board: false,
				closed_clients: false,
				import: false,
				export: false,
				allocations: false,
				true_link_view: false,
				true_link_edit: false,
				edit_transactions: false,
				client_details: false,
				add_deposit: false,
				add_fee: false,
				add_payment: false,
				restrict_one_time_change: false,
				payments_role: false,
				check_log_view: false,
				check_log_add: false,
				add_user: false,
				scan_documents: false,
				final_approval: false,
				view_closed: false,
				welcome_packets: false,
			}
		})
	}

  canBeSubmitted() {
    const errors = validate(this.state.name,this.state.email);
    const isDisabled = Object.keys(errors).some(x => errors[x]);
    return !isDisabled;
  }

  showError(detail) {
    let msg = {severity: 'error', summary: 'Error Message', detail: detail };
    this.growl.show(msg);
  }

	render() {
		const errors = validate(this.state.name,this.state.email, this.state.password);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    const dialogHeader = (
      <div>
        <h1>New User</h1>
      </div>
    );

    const dialogFooter = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={() => this.createUser() }
          label="Create"
          disabled={isDisabled}
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({displayNew: false}) }
          label="Cancel"
        />
      </div>
    );

    const dialogHeaderEdit = (
      <div>
        <h1>Edit User</h1>
      </div>
    );
    
    const dialogFooterEdit = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={() => this.editUser() }
          label="Update"
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({displayEdit: false}) }
          label="Cancel"
        />
      </div>
    );
    // const editName = this.state.editName

		return (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card">
						<div className="card-header">
							<h1>Users</h1>
						</div>
						<DataTable 
							value={this.state.users}
							selection={this.state.dataTableSelection}
							selectionMode="single"
							responsive={true}
							onSelectionChange={event => this.selectUser(event)}
						>
							<Column field="name" header="Name" sortable={true} />
							<Column field="email" header="Email" sortable={true} />
						</DataTable>
						<Buttons selection={this.getSelected()} newUser={() => this.setState({displayNew: true})} editUser={() => this.setState({displayEdit: true})} handleDelete={(e) => { if (window.confirm('Are you sure you wish to delete this user?')) this.handleDelete() } }/>
					</div>
				</div>
				{ /* NEW DIALOG */ }
				<Dialog
	        header={dialogHeader}
	        footer={dialogFooter}
	        visible={this.state.displayNew}
	        modal={true}
	        width="700px"
	        closable={true}
	        className="new-user"
	        onHide={ () => this.setState({displayNew: false}) }
	      >
	        <div className="card card-w-title">
	          <div
	            className="p-grid p-justify-center"
	            style={{ textAlign: "center" }}
	          >
	            <div className="p-col-12">
	              <div className={shouldMarkError("name") ? "p-inputgroup error" : "p-inputgroup"} >
	                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
	                  <InputText id="name" onChange={this.handleChange} placeholder="Name"  onBlur={this.handleBlur("name")} />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div className={shouldMarkError("email") ? "p-inputgroup error" : "p-inputgroup"}>
	                  <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
	                  <InputText id="email" onChange={this.handleChange} placeholder="Email" onBlur={this.handleBlur("email")} />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div className={shouldMarkError("password") ? "p-inputgroup error" : "p-inputgroup"}>
	                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
	                  <InputText 
	                    id="password" 
	                    onChange={this.handleChange}
	                    onBlur={this.handleBlur("password")}
	                    placeholder="Password"
	                   />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div className={shouldMarkError("password_confirmation") ? "p-inputgroup error" : "p-inputgroup"}>
	                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
	                  <InputText 
	                    id="password_confirmation" 
	                    onChange={this.handleChange}
	                    onBlur={this.handleBlur("password_confirmation")}
	                    placeholder="Password Confirmation"
	                   />
	              </div>
	            </div>
	          </div>
	        </div>
	      </Dialog>

	    { /* EDIT DIALOG */ }
				<Dialog
	        header={dialogHeaderEdit}
	        footer={dialogFooterEdit}
	        visible={this.state.displayEdit}
	        modal={true}
	        width="700px"
	        closable={true}
	        className="new-user"
	        onHide={ () => this.setState({displayEdit: false}) }
	      >
	        <div className="card card-w-title">
	          <div
	            className="p-grid p-justify-center"
	            style={{ textAlign: "center" }}
	          >
	            <div className="p-col-12">
	              <div className="p-inputgroup" >
	                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
	                  <InputText id="name" onChange={this.handleChange} placeholder="Name"  value={this.state.name} />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div className="p-inputgroup">
	                  <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
	                  <InputText id="email" onChange={this.handleChange} placeholder="Email" onBlur={this.handleBlur("email")} value={this.state.email}/>
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div className="p-inputgroup">
	                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
	                  <InputText 
	                    id="password" 
	                    onChange={this.handleChange}
	                    placeholder="Password - Leave Blank to keep current"
	                   />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div>
	                <h2>Permissions</h2>
	              </div>
	              <div className="p-grid permissions">
	              	<div className="p-col-6">
										<Checkbox id="select_all" onChange={this.select_all} checked={this.state.select_all } ></Checkbox>
										<label htmlFor="select_all" className="p-checkbox-label">Select All</label>
									</div>
									<div className="p-col-6">
										<Checkbox id="select_none" onChange={this.select_none} checked={this.state.select_none } ></Checkbox>
										<label htmlFor="select_none" className="p-checkbox-label">Select None</label>
									</div>
		              <div className="p-col-4">
										<Checkbox id="edit_pending_clients" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.edit_pending_clients : "" } ></Checkbox>
										<label htmlFor="edit_pending_clients" className="p-checkbox-label">Edit Pending Clients</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="classifictions" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.classifictions : "" } ></Checkbox>
										<label htmlFor="classifictions" className="p-checkbox-label">Classifications</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="add_new_clients" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.add_new_clients : "" } ></Checkbox>
										<label htmlFor="add_new_clients" className="p-checkbox-label">Add New Clients</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="scan_documents" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.scan_documents : "" } ></Checkbox>
										<label htmlFor="scan_documents" className="p-checkbox-label">Scan Documents</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="approve_documents" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.approve_documents : "" } ></Checkbox>
										<label htmlFor="approve_documents" className="p-checkbox-label">Approve Documents</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="approve_for_board" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.approve_for_board : "" } ></Checkbox>
										<label htmlFor="approve_for_board" className="p-checkbox-label">Approve For Board</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="final_approval" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.final_approval : "" } ></Checkbox>
										<label htmlFor="final_approval" className="p-checkbox-label">Final Approval</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="view_closed" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.view_closed : "" } ></Checkbox>
										<label htmlFor="view_closed" className="p-checkbox-label">View Closed Clients</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="closed_clients" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.closed_clients : "" } ></Checkbox>
										<label htmlFor="closed_clients" className="p-checkbox-label">Close Clients</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="import" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.import : "" } ></Checkbox>
										<label htmlFor="import" className="p-checkbox-label">Import</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="export" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.export : "" } ></Checkbox>
										<label htmlFor="export" className="p-checkbox-label">Export</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="allocations" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.allocations : "" } ></Checkbox>
										<label htmlFor="allocations" className="p-checkbox-label">Allocations</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="true_link_view" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.true_link_view : "" } ></Checkbox>
										<label htmlFor="true_link_view" className="p-checkbox-label">True Link View</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="true_link_edit" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.true_link_edit : "" } ></Checkbox>
										<label htmlFor="true_link_edit" className="p-checkbox-label">True Link Edit</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="edit_transactions" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.edit_transactions : "" } ></Checkbox>
										<label htmlFor="edit_transactions" className="p-checkbox-label">Edit Transactions</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="client_details" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.client_details : "" } ></Checkbox>
										<label htmlFor="client_details" className="p-checkbox-label">Client Details</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="add_deposit" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.add_deposit : "" } ></Checkbox>
										<label htmlFor="add_deposit" className="p-checkbox-label">Add Deposit</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="add_fee" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.add_fee : "" } ></Checkbox>
										<label htmlFor="add_fee" className="p-checkbox-label">Add Fee</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="add_payment" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.add_payment : "" } ></Checkbox>
										<label htmlFor="add_payment" className="p-checkbox-label">Add Payment</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="restrict_one_time_change" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.restrict_one_time_change : "" } ></Checkbox>
										<label htmlFor="restrict_one_time_change" className="p-checkbox-label">One Time Change</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="payments_role" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.payments_role : "" } ></Checkbox>
										<label htmlFor="payments_role" className="p-checkbox-label">Payments Role</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="check_log_view" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.check_log_view : "" } ></Checkbox>
										<label htmlFor="check_log_view" className="p-checkbox-label">Check Log View</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="check_log_add" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.check_log_add : "" } ></Checkbox>
										<label htmlFor="check_log_add" className="p-checkbox-label">Check Log Add</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="welcome_packets" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.welcome_packets : "" } ></Checkbox>
										<label htmlFor="welcome_packets" className="p-checkbox-label">Welcome Packets</label>
									</div>
									<div className="p-col-4">
										<Checkbox id="add_user" onChange={this.handlePermissions} checked={this.state.permissions ? this.state.permissions.add_user : "" } ></Checkbox>
										<label htmlFor="add_user" className="p-checkbox-label">Add Users</label>
									</div>
								</div>
	            </div>
	          </div>
	        </div>
	      </Dialog>

	    {/* ERROR MESSAGES */}
	    <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
			</div>
		);
	}
}