// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.p-menuitem-active.p-button-danger > .p-menuitem-link {
  background-color: red !important;
}
li.p-menuitem-active.p-button-danger > .p-menuitem-link .p-menuitem-text, li.p-menuitem-active.p-button-danger > .p-menuitem-link .p-menuitem-icon {
  color: white !important;
}

.common.card {
  margin-bottom: 100px;
}

.case-notes-button {
  position: fixed;
  bottom: 0px;
  right: 15em;
  background: #5CB85C;
  color: white;
  padding: 1em;
  padding-bottom: 10px;
  padding-top: 0.5em;
  border-radius: 10px 10px 0px 0px;
  font-size: 20px;
  border: none;
}
.case-notes-button:hover {
  background: #3c9a3c;
  cursor: pointer;
}
.case-notes-button:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/views/Client/c2.scss"],"names":[],"mappings":"AAAA;EACC,gCAAA;AACD;AAAC;EACC,uBAAA;AAEF;;AACA;EACC,oBAAA;AAED;;AAAA;EACE,eAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,YAAA;AAGF;AADE;EACC,mBAAA;EACA,eAAA;AAGH;AADE;EACC,aAAA;AAGH","sourcesContent":["li.p-menuitem-active.p-button-danger > .p-menuitem-link{\n\tbackground-color: red !important;\n\t.p-menuitem-text, .p-menuitem-icon {\n\t\tcolor: white !important;\n\t}\n}\n.common.card {\n\tmargin-bottom: 100px;\n}\n.case-notes-button {\n  position: fixed;\n  bottom: 0px;\n  right: 15em;\n  background: #5CB85C;\n  color: white;\n  padding: 1em;\n  padding-bottom: 10px;\n  padding-top: .5em;\n  border-radius: 10px 10px 0px 0px;\n  font-size: 20px;\n  border: none;\n\n  &:hover {\n  \tbackground: #3c9a3c;\n  \tcursor: pointer;\t\n  }\n  &:focus {\n  \toutline:none; \t\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
