import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppInlineProfile} from './AppInlineProfile';
import {Route,Link} from 'react-router-dom';
import {Dashboard} from './components/Dashboard.jsx';
import {MainMenu} from './components/Menu';
import {Users} from './views/Users/Users';
import {Login} from './views/Login/Login';
import {ClientList} from './views/ClientList';
import {Client} from './views/Client/Client';
import {NewClient} from './views/NewClient/NewClient';
import {Classifications} from './views/Classification/Classifications';
import {Export} from './views/Exports/Export';
import {Archived} from './views/Archived/Archived';
import {Import} from './views/Imports/Import';
import {Reports} from './views/Reports/Reports';
import {AllReports} from './views/Reports/AllReports';
import {TrueLinkClients} from './views/TrueLink/TrueLink';
import {TrueLink} from './components/TrueLink';
import {AlertList} from './views/AlertList';
import {Allocation} from './views/Allocations/Allocations';
import {CheckLog} from "./components/CheckLog";
import {CheckLogCleared} from "./components/CheckLogCleared";
import {EditTransactionsTable} from './views/EditTransactionsTable/EditTransactionsTable';
import {FormsDemo} from './components/FormsDemo';
import {SampleDemo} from './components/SampleDemo';
import {DataDemo} from './components/DataDemo';
import {PanelsDemo} from './components/PanelsDemo';
import {OverlaysDemo} from './components/OverlaysDemo';
import {MenusDemo} from './components/MenusDemo';
import {MessagesDemo} from './components/MessagesDemo';
import {ChartsDemo} from './components/ChartsDemo';
import {MiscDemo} from './components/MiscDemo';
import {EmptyPage} from './components/EmptyPage';
import {Documentation} from "./components/Documentation";
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
// import PropTypes from 'prop-types';
import PrivateRoute from './components/PrivateRoute';
// import { createBrowserHistory } from 'history';
import logo from './assets/Pooledicon2.png'

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fullcalendar/dist/fullcalendar.css';
import './layout/layout.css';
import './App.css';
import {API_URL} from './helpers/api_helpers'
// const axios = require('axios');
import axios, {isCancel, AxiosError} from 'axios';

// const Protected = () => <h3>Protected</h3>

class App extends Component {
		
	constructor() {
		// this.history = this.props.history;
		super();
		this.state = {
				layoutMode: 'static',
				layoutColorMode: 'dark',
				staticMenuInactive: false,
				overlayMenuActive: false,
				mobileMenuActive: false,
				loggedIn: false,
				userName: "",
				userRole: null,
				userPermissions: null,
				trusts: [],
				menu: "",
		};
		this.loadUserFromToken();
		this.getTrusts();

		this.onWrapperClick = this.onWrapperClick.bind(this);
		this.onToggleMenu = this.onToggleMenu.bind(this);
		this.onSidebarClick = this.onSidebarClick.bind(this);
		this.onMenuItemClick = this.onMenuItemClick.bind(this);
		this.logout = this.logout.bind(this);
		this.getTrusts = this.getTrusts.bind(this);
	}

	getTrusts(){
		var url = API_URL + "/api/v1/trusts";
		axios.get(url,{headers: {Authorization: sessionStorage.getItem('jwtToken')} } )
		.then(( res ) => {
			if (res) {
				this.setState({trusts: res.data.trusts});
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}


		loadUserFromToken() {
			let token = sessionStorage.getItem('jwtToken');
			if(!token || token === '') { 
				// Dont bother if there is no token
				return;
			} else {
				// Verify the user.
				
				var url = API_URL + "/api/v1/test";
				axios.get(url, {
					headers: {
						Authorization: sessionStorage.getItem('jwtToken'),
					}
				})
				.then(( res ) => {
					this.setState({ loggedIn: true, userName: sessionStorage.getItem('name'), userRole: sessionStorage.getItem('role'), userPermissions: sessionStorage.getItem('permissions') });
				})
				.catch( ( error ) => {
					this.setState({ loggedIn: false });
					sessionStorage.clear();
					window.location = "/";
				})
				.then( ( res ) => {
					// always executed
				});
			}
		}

		onWrapperClick(event) {
				if (!this.menuClick) {
						this.setState({
								overlayMenuActive: false,
								mobileMenuActive: false
						});
				}

				this.menuClick = false;
		}

		onToggleMenu(event) {
				this.menuClick = true;

				if (this.isDesktop()) {
						if (this.state.layoutMode === 'overlay') {
								this.setState({
										overlayMenuActive: !this.state.overlayMenuActive
								});
						}
						else if (this.state.layoutMode === 'static') {
								this.setState({
										staticMenuInactive: !this.state.staticMenuInactive
								});
						}
				}
				else {
						const mobileMenuActive = this.state.mobileMenuActive;
						this.setState({
								mobileMenuActive: !mobileMenuActive
						});
				}
			 
				event.preventDefault();
		}

		onSidebarClick(event) {
				this.menuClick = true;
				setTimeout(() => {this.layoutMenuScroller.moveBar(); }, 500);
		}

		onMenuItemClick(event) {
				if(!event.item.items) {
						this.setState({
								overlayMenuActive: false,
								mobileMenuActive: false
						})
				}
		}

		logout() {
			this.setState({ loggedIn: false });
			sessionStorage.clear();
			window.location = "/";
		}

		addClass(element, className) {
				if (element.classList)
						element.classList.add(className);
				else
						element.className += ' ' + className;
		}

		removeClass(element, className) {
				if (element.classList)
						element.classList.remove(className);
				else
						element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}

		isDesktop() {
				return window.innerWidth > 1024;
		}

		componentDidUpdate() {
				if (this.state.mobileMenuActive)
						this.addClass(document.body, 'body-overflow-hidden');
				else
						this.removeClass(document.body, 'body-overflow-hidden');
		}

		componentWillMount(){
			
		}

		render() {

				let wrapperClass = classNames('layout-wrapper', {
						'layout-overlay': this.state.layoutMode === 'overlay',
						'layout-static': this.state.layoutMode === 'static',
						'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
						'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
						'layout-mobile-sidebar-active': this.state.mobileMenuActive
				});
				let sidebarClassName = classNames("layout-sidebar", {'layout-sidebar-dark': this.state.layoutColorMode === 'dark'});

				const routeComponents = this.state.trusts.map((t,key) => 
					<React.Fragment key={key}>
						<Route exact path={"/"+t.name.replace(" ","-").toLowerCase()} key={key} render={(props) => <ClientList {...props} trust={t} status={"active"} />} />
						<Route exact path={"/"+t.name.replace(" ","-").toLowerCase() + "/new-client"} key={key + 2000 } render={(props) => <NewClient {...props} trust={t} />} />
						<Route exact path={"/"+t.name.replace(" ","-").toLowerCase() + "/pending-clients"} key={key + 3000} render={(props) => <ClientList {...props} trust={t} status={"pending"} />} />
						<Route exact path={"/"+t.name.replace(" ","-").toLowerCase() + "/disabled-clients"} key={key + 4000} render={(props) => <ClientList {...props} trust={t} status={"disable"} />} />
					</React.Fragment>
				);

				return (
						<div className={wrapperClass} onClick={this.onWrapperClick}>
								<AppTopbar onToggleMenu={this.onToggleMenu}/>

								<div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

										<ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height:'100%'}}>
												<div className="layout-sidebar-scroll-content" >
														<div className="layout-logo">
																<img alt="Logo" src={logo} />
																<div className="logo-title">WNY Pooled Trust</div>
														</div>
														<AppInlineProfile logout={this.logout} username={this.state.userName}/>
														<div className="menu">
															<ul>
																<li>
																	<Link to="/dashboard">
																		<i className="pi pi-fw pi-home"></i>Dashboard
																	</Link>
																</li>
																
															</ul>
														</div>
														<MainMenu loggedIn={this.state.loggedIn} trusts={this.state.trusts}/>
												</div>
										</ScrollPanel>
								</div>

								<div className="layout-main">
									
										{this.state.loggedIn ? <Route exact path="/" component={Dashboard} /> : <Route exact path="/" render={(props) => <Login {...props} login={this.login} />} /> }
										<Route path="/dashboard" component={Dashboard} />
										<Route path="/forms" component={FormsDemo} />
										<Route path="/sample" component={SampleDemo} />
										<Route path="/data" component={DataDemo} />
										<Route path="/panels" component={PanelsDemo} />
										<Route path="/overlays" component={OverlaysDemo} />
										<Route path="/menus" component={MenusDemo} />
										<Route path="/messages" component={MessagesDemo} />
										<Route path="/charts" component={ChartsDemo} />
										<Route path="/misc" component={MiscDemo} />
										<Route path="/empty" component={EmptyPage} />
										<Route path="/documentation" component={Documentation} />
										{routeComponents}
										<PrivateRoute path="/users" component={Users} />
										<PrivateRoute path="/classifications" component={Classifications} />
										<PrivateRoute path="/client" component={Client} />
										<PrivateRoute path="/edittransactions" component={EditTransactionsTable} />
										<PrivateRoute path="/exports" component={Export} />
										<PrivateRoute path="/archived" component={Archived} />
										<PrivateRoute path="/imports" component={Import} />
										<PrivateRoute path="/true-link/:id" component={TrueLink} />
										<PrivateRoute path="/allocations" component={Allocation} />
										<PrivateRoute path="/6-month-reports" component={Reports} />
										<PrivateRoute path="/reports" component={AllReports} />
										<PrivateRoute path="/true-link-clients" component={TrueLinkClients} />
										<PrivateRoute path="/check-log-current" component={CheckLog} />
										<PrivateRoute path="/check-log-cleared" component={CheckLogCleared} />
										<Route path="/lowfunds" render={(props) => <AlertList {...props} astatus={"low_funds"}/>} />
										<Route path="/misseddeposits" render={(props) => <AlertList {...props} astatus={"missed_deposits"}/>} />
										<Route path="/notspending" render={(props) => <AlertList {...props} astatus={"not_spending"}/>} />
										<Route path="/inactiveandlowfunds" render={(props) => <AlertList {...props} astatus={"inactive_and_low_funds"}/>} />
										<Route path="/truelinkadditionalactioninactive" render={(props) => <AlertList {...props} astatus={"true_link_additional_action_inactive"}/>} />
										<Route path="/truelinkapprovedinactive" render={(props) => <AlertList {...props} astatus={"true_link_approved_inactive"}/>} />
										<Route exact path="/login" render={(props) => <Login {...props} login={this.login} />} />
										{/*<Route component={Login} />*/}

								</div>

								{/*<AppFooter /> */}

								<div className="layout-mask"></div>

						</div>
				);
		}
}

export default App;


