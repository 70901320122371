import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Growl} from 'primereact/growl';
import {Calendar} from 'primereact/calendar';
import {SelectButton} from 'primereact/selectbutton';
import {Dialog} from "primereact/dialog";
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";

import './view.css'

import {API_URL} from '../../helpers/api_helpers'
import axios, {isCancel, AxiosError} from 'axios';


export class Export extends Component {
	static propTypes = {
    trust: PropTypes.object,
  };

	constructor(props) {
		super(props);
		this.state = {
      files: [],
      categories: {},
      category_select: [
        {label: 'Board', value: 'board'},
        {label: 'Weekly', value: 'weekly'},
      ],
      category: "",
      subcategory_select: [
        {label: 'Approval', value: 'approval'},
        {label: 'Bank', value: 'bank'},
      ],
      subcategory: "",
      showExportModal: false,
      date: new Date(),
      optionalname: "",
		}
    this.download = this.download.bind(this);
	}

  componentWillMount(){
    var permissions = JSON.parse(sessionStorage.permissions)
    if ( permissions.export === false || permissions.export === undefined ){
      window.location = "/"
    }
    this.get_category_list();
    this.get_export_files();
  }

	handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  get_category_list(){
    var url = API_URL + "/api/v1/export_file_categories";
    axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      } 
    })
    .then(( res ) => {
      if (res) {
        var main_categories = [];
        var sub_categories = [];
        res.data.export_file_categories.map(function(value,key){
          if(value.parent_id === null) {
            main_categories.push(value);
          } else {
            sub_categories.push(value);
          }
          return null;
        });
        var categories = {};
        categories.main = main_categories;
        categories.sub = sub_categories;
        this.setState({categories: categories})
      }
    });
  }

  get_export_files(){
    var url = API_URL + "/api/v1/export_files";
    axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      } 
    })
    .then(( res ) => {
      console.log("Getting new data files: ")
      console.log(res.data.export_files)
      if (res) {
        this.setState({files: res.data.export_files})
      }
    });
  }

  build_category_list(){
    if (this.state.categories.main) {
      var that = this
      var list = this.state.categories.main.map(function(value, key){
        return (
          <div className="card relative" key={key}>
            <h2 style={{color:"Red"}}>{value.name}</h2>
            <div className="category">
              {that.build_list(value.id)}
            </div>
            <div className="">
              <button className="top-right p-button p-button-warning default-btn" onClick={ () => that.setState({showExportModal: true, category_id: value.id, category: value.name }) }>Create</button>
            </div>
          </div>
        );
      });
      return list
    }
  }

  archive(file_id,file_name){
    var url = API_URL + "/api/v1/export_files/archive/" + file_id;
    var that = this
    axios.put(url,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      } 
    })
    .then(( res ) => {
      if (res) {
        console.log(res)
        if (res.data.export_file.id == file_id){
          that.get_export_files();
        }
        console.log("Archiving data files: ")
      }
    });
  }

  build_list(cat_id){
    var that = this;
    var list = this.state.files.map(function(file,index){
      if ( file.category_id === cat_id){
        return (
          <React.Fragment key={index}>
            {file.name}
            &nbsp;&nbsp;  <button href="#" onClick={() => that.download(file.id,file.name) } > Download</button>&nbsp;&nbsp;
            <button href="#" onClick={() => that.archive(file.id,file.name) } > Archive</button><br />
          </React.Fragment>
        )
      } else {
        return null;
      }
    })
    return list;
  }

  async download(file_id,file_name){
    // GENERATE THE FILE
    var url = API_URL + "/api/v1/export_files/" + file_id
    var data = { export_file: {
      regenerate_file: true
    }}
    await axios.put(url,data,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      },
    }).then((response) => {
      console.log('File Generated');
    });
    // DOWNLOAD THE FILE
    url = API_URL + "/api/v1/export_files/" + file_id + "/download?"+Date.now()
    axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name + ".xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  addFile(){
    var optionalname = ""
    if ( this.state.optionalname !== "" ){
      optionalname = "-" + this.state.optionalname
    }
    var data = {
      export_file: {
        name: "",
        data_type: "",
        category_id: ""
      }
    }
    switch(this.state.category) {
      case "Management Approval":
        // PreApproval
        data.export_file.name = this.state.category_selected + "-" + this.state.date.toISOString().slice(0,10) + optionalname;
        data.export_file.data_type = "approval"
        data.export_file.category_id = this.state.category_id
        this.generate_file(data)
        console.log(data);
        break;
      default:
        var data_type = ""
        if ( this.state.category === "Disbursements"){
          data_type = "payment";
        }
        if ( this.state.category === "Fees"){
          data_type = "fee";
        }
        if ( this.state.category === "Deposits"){
          data_type = "deposit";
        }
        data.export_file.name = this.state.category + "-" + this.state.date.toISOString().slice(0,10) + optionalname;
        data.export_file.data_type = data_type
        data.export_file.category_id = this.state.category_id
        this.generate_file(data)
    }
    this.setState({category: "", subcategory: ""})
  }

  generate_file(data){
    var url = API_URL + "/api/v1/export_files";
    axios.post(url,data, {
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      } 
    })
    .then(( res ) => {
      if (res) {
        console.log(res)
        this.get_category_list()
        this.get_export_files()
        this.setState({showExportModal: false})
      } else {
        let msg = {severity: 'error', summary: 'Error Message', detail: 'Failed to create file'};
        this.growl.show(msg);
      }
    })
    .catch( ( error ) => {
      console.log(error);
    });
  }

  render() {
    const dialogHeader = (
      <div>
        <h1>New Export File</h1>
      </div>
    );

    const dialogFooter = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={() => this.addFile() }
          label="Create"
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({showExportModal: false}) }
          label="Cancel"
        />
      </div>
    );

    const category_list = this.build_category_list()

  	return (
  		<div>
  			<div className="p-col-12 deposit-info">
          <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
          <div className="card relative">
            <h1>Export Files</h1>
          </div>
          <div>
            {category_list}
          </div>
        </div>
        
        { /* DEPOSIT DIALOG */ }
        <Dialog
          header={dialogHeader}
          footer={dialogFooter}
          visible={this.state.showExportModal}
          modal={true}
          width="200px"
          closable={true}
          className="new-user"
          onHide={ () => this.setState({showExportModal: false}) }
        >
          <div className="card card-w-title">
            <div
              className="p-grid p-justify-center"
              style={{ textAlign: "center" }}
            >
              <div className="p-col-12">
                <div>
                  {
                    this.state.category === "Management Approval" ? 
                      <SelectButton value={this.state.category_selected} options={this.state.category_select} onChange={(e) => this.setState({category_selected: e.value})}></SelectButton> 
                    : 
                      <InputText id="optionalname" onChange={this.handleChange} placeholder="Optional Filename" />
                  }
                  <Calendar value={this.state.date} onChange={(e) => this.setState({date: e.value})}></Calendar>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

  		</div>
  	)
  }
}