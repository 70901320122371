// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close_account {
	width: 50%;
	float: right;
}

.close_account textarea {
	margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/summary.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".close_account {\n\twidth: 50%;\n\tfloat: right;\n}\n\n.close_account textarea {\n\tmargin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
