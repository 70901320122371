import React, {Component} from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {InputText} from "primereact/inputtext";
// import {Password} from 'primereact/password';
// import {Dropdown} from 'primereact/dropdown';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
// import {DataView, DataViewLayoutOptions} from 'primereact/dataview';
import {Column} from 'primereact/column';
import {Growl} from 'primereact/growl';
import {API_URL} from '../../helpers/api_helpers'
import './new.css';

import axios from 'axios';

class Buttons extends Component {
	
	render(){
		return (
			<div className="crud-btns new-user">
				<button className="p-button p-button-rounded" onClick={this.props.newClassification}>New</button>
				{ this.props.selection != null &&
					<React.Fragment>
						<button className="p-button p-button-rounded p-button-warning" onClick={this.props.editClassification}>Edit</button>
						<button className="p-button p-button-rounded p-button-danger" onClick={this.props.handleDelete}>Delete</button>
					</React.Fragment>
				}
			</div>
		)
	}
}

export class Classifications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayNew: false,
			displayEdit: false,
			classifications: [],
			dataTableSelection: null,
      name: "",
      description: "",
      
		};
		this.handleDelete = this.handleDelete.bind(this);
		this.selectClassification = this.selectClassification.bind(this);
		this.editClassification = this.editClassification.bind(this);
		this.createClassification = this.createClassification.bind(this);
		this.getSelected = this.getSelected.bind(this);
	}

	componentDidMount() {
		this.getclassifications();
	}

	componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.classifictions === false || permissions.classifictions === undefined ) {
			window.location = "/"
		}
	}

	getclassifications() {
		// const history = this.props.history;
		var url = API_URL + "/api/v1/classifications";
		axios.get(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(({ data }) => {
				console.log(data);
				this.setState({
					classifications: data.classifications,
				});
			});
	}

	selectClassification(event) {
		this.state.dataTableSelection === event.value ? this.setState({dataTableSelection: null, name:"",description:""}) : this.setState({dataTableSelection: event.value,name:event.value.name,description:event.value.description})
	}

	getSelected(){
		return this.state.dataTableSelection
	}

	createClassification(){
		var url = API_URL + "/api/v1/classifications";
		axios.post(url, {classification: {
			name: this.state.name,
			description: this.state.description}
		}, {headers: {Authorization: sessionStorage.getItem('jwtToken')} })
		.then(( res ) => {
			if (res.status !== 215) {
				this.getclassifications();
				this.setState({displayNew: false});
			} else {
				this.showError(res.data.name);
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	editClassification(){
		var url = API_URL + "/api/v1/classifications/"+this.state.dataTableSelection.id;
		var data = {
			id: this.state.dataTableSelection.id,
			name: this.state.name,
			description: this.state.description,
		}
		axios.put(url, data, {headers: {Authorization: sessionStorage.getItem('jwtToken')} } )
		.then(( res ) => {
			if (res.status !== 215) {
				this.getclassifications();
				this.setState({displayEdit: false});
			} else {
				this.showError(res.data.name);
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	handleDelete() {
		const { classifications } = this.state;
		var url = API_URL + "/api/v1/classifications/"+this.state.dataTableSelection.id;
    axios.delete(url, {headers: {
      Authorization: sessionStorage.getItem('jwtToken')}
    }
    )
		.then((res) => {
			this.setState({
				classifications: classifications.filter(u => u.id !== this.state.dataTableSelection.id),
				dataTableSelection: '',
			});
		})
	}

	handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

	render() {
    const dialogHeader = (
      <div>
        <h1>New Classification</h1>
      </div>
    );

    const dialogFooter = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={() => this.createClassification() }
          label="Create"
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({displayNew: false}) }
          label="Cancel"
        />
      </div>
    );

    const dialogHeaderEdit = (
      <div>
        <h1>Edit Classification</h1>
      </div>
    );
    
    const dialogFooterEdit = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={() => this.editClassification() }
          label="Update"
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({displayEdit: false}) }
          label="Cancel"
        />
      </div>
    );
    // const editName = this.state.editName

		return (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card">
						<div className="card-header">
							<h1>classifications</h1>
						</div>
						<DataTable 
							value={this.state.classifications}
							selection={this.state.dataTableSelection}
							selectionMode="single"
							responsive={true}
							onSelectionChange={event => this.selectClassification(event)}
						>
							<Column field="name" header="Name" sortable={true} />
							<Column field="description" header="Description" sortable={true} />
						</DataTable>
						<Buttons selection={this.getSelected()} newClassification={() => this.setState({displayNew: true})} editClassification={() => this.setState({displayEdit: true})} handleDelete={(e) => { if (window.confirm('Are you sure you wish to delete this classification?')) this.handleDelete() } }/>
					</div>
				</div>
				{ /* NEW DIALOG */ }
				<Dialog
	        header={dialogHeader}
	        footer={dialogFooter}
	        visible={this.state.displayNew}
	        modal={true}
	        width="200px"
	        closable={true}
	        className="new-user"
	        onHide={ () => this.setState({displayNew: false}) }
	      >
	        <div className="card card-w-title">
	          <div
	            className="p-grid p-justify-center"
	            style={{ textAlign: "center" }}
	          >
	            <div className="p-col-12">
	              <div>
	                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
	                  <InputText id="name" onChange={this.handleChange} placeholder="Name" />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div>
	                  <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
	                  <InputText id="description" onChange={this.handleChange} placeholder="Description" />
	              </div>
	            </div>
	          </div>
	        </div>
	      </Dialog>

	    { /* EDIT DIALOG */ }
				<Dialog
	        header={dialogHeaderEdit}
	        footer={dialogFooterEdit}
	        visible={this.state.displayEdit}
	        modal={true}
	        width="200px"
	        closable={true}
	        className="new-user"
	        onHide={ () => this.setState({displayEdit: false}) }
	      >
	        <div className="card card-w-title">
	          <div
	            className="p-grid p-justify-center"
	            style={{ textAlign: "center" }}
	          >
	            <div className="p-col-12">
	              <div className="p-inputgroup" >
	                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
	                  <InputText id="name" onChange={this.handleChange} placeholder="Name"  value={this.state.name} />
	              </div>
	            </div>
	            <div className="p-col-12">
	              <div className="p-inputgroup">
	                  <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
	                  <InputText id="description" onChange={this.handleChange} placeholder="Description" value={this.state.description}/>
	              </div>
	            </div>
	          </div>
	        </div>
	      </Dialog>

	    {/* ERROR MESSAGES */}
	    <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
			</div>
		);
	}
}