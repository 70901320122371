// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-user .p-dialog-content {
	border: 0px !important;
	border-radius: 0px !important;

}
.new-user .p-dialog-titlebar {
	background-color: #5CB85C !important;
	border:0 !important;
	text-align: center;
	color: #ffffff !important;
}

.new-user .dialog-sign-in {
	font-size: 40px;
	color: #10497F;
	font-weight: 700;
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.new-user .p-inputgroup {
	justify-content: center;
  /*width: 70%;*/
  margin: auto;
}

.new-user .p-inputtext:hover {
	border: 1px solid #a6a6a6 !important;
	/*border-left:0 !important;*/
}

.new-user .p-inputtext.p-component {
	width: 100%;
	outline: none;
	background-color: #eaeaea;
}

.new-user .p-button {
	padding: 0px 20px !important;
  margin-top: 20px !important;
  margin-right: 10px !important;
  font-size: 20px !important;
}

.new-user .p-inputgroup.error {
	border: 1px solid red;
	border-radius: 3px;
}


.new-user .default-btn:focus {
	/*box-shadow: none !important;*/
}
.new-user .default-btn:hover {
	box-shadow: 0 0 0 0.2em #8dcdff !important;
}

.new-user .p-dialog-titlebar-icons {
	top: 10px;
  position: absolute;
  right: 10px;
}

#role {
	width: 70% !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Classification/new.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,6BAA6B;;AAE9B;AACA;CACC,oCAAoC;CACpC,mBAAmB;CACnB,kBAAkB;CAClB,yBAAyB;AAC1B;;AAEA;CACC,eAAe;CACf,cAAc;CACd,gBAAgB;CAChB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,uBAAuB;EACtB,cAAc;EACd,YAAY;AACd;;AAEA;CACC,oCAAoC;CACpC,4BAA4B;AAC7B;;AAEA;CACC,WAAW;CACX,aAAa;CACb,yBAAyB;AAC1B;;AAEA;CACC,4BAA4B;EAC3B,2BAA2B;EAC3B,6BAA6B;EAC7B,0BAA0B;AAC5B;;AAEA;CACC,qBAAqB;CACrB,kBAAkB;AACnB;;;AAGA;CACC,+BAA+B;AAChC;AACA;CACC,0CAA0C;AAC3C;;AAEA;CACC,SAAS;EACR,kBAAkB;EAClB,WAAW;AACb;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".new-user .p-dialog-content {\n\tborder: 0px !important;\n\tborder-radius: 0px !important;\n\n}\n.new-user .p-dialog-titlebar {\n\tbackground-color: #5CB85C !important;\n\tborder:0 !important;\n\ttext-align: center;\n\tcolor: #ffffff !important;\n}\n\n.new-user .dialog-sign-in {\n\tfont-size: 40px;\n\tcolor: #10497F;\n\tfont-weight: 700;\n\tmargin-top: 3rem;\n\tmargin-bottom: 3rem;\n}\n\n.new-user .p-inputgroup {\n\tjustify-content: center;\n  /*width: 70%;*/\n  margin: auto;\n}\n\n.new-user .p-inputtext:hover {\n\tborder: 1px solid #a6a6a6 !important;\n\t/*border-left:0 !important;*/\n}\n\n.new-user .p-inputtext.p-component {\n\twidth: 100%;\n\toutline: none;\n\tbackground-color: #eaeaea;\n}\n\n.new-user .p-button {\n\tpadding: 0px 20px !important;\n  margin-top: 20px !important;\n  margin-right: 10px !important;\n  font-size: 20px !important;\n}\n\n.new-user .p-inputgroup.error {\n\tborder: 1px solid red;\n\tborder-radius: 3px;\n}\n\n\n.new-user .default-btn:focus {\n\t/*box-shadow: none !important;*/\n}\n.new-user .default-btn:hover {\n\tbox-shadow: 0 0 0 0.2em #8dcdff !important;\n}\n\n.new-user .p-dialog-titlebar-icons {\n\ttop: 10px;\n  position: absolute;\n  right: 10px;\n}\n\n#role {\n\twidth: 70% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
