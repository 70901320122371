import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {InputMask} from 'primereact/inputmask';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {ScrollPanel} from 'primereact/scrollpanel';

import {API_URL} from '../helpers/api_helpers'
import {formatDate} from '../helpers/formats'
import {stringToDate} from '../helpers/formats'

import axios from 'axios';

export class Details extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	updateClient(){
		var url = API_URL + "/api/v1/clients/" + this.props.client.id ;
		// change mailing address to blank if is turned off
		if (this.state.differentMailing === false) {
			let client = this.state.client
			client.mailing_address1 = ""
			client.mailing_address2 = ""
			client.mailing_city = ""
			client.mailing_state = ""
			client.mailing_zip = ""
			this.setState({client})
		}
		// change the classification
		if (this.state.classification_id) {
			let client = this.state.client
			client.classification_id = this.state.classification_id
			this.setState({client})
		}
		// set the disablity
		var client = this.state.client
		client.disability = this.state.disability
		this.setState({client})
		// change the disablity info to blank if no disablity.
		if (this.state.disability === false) {
			let client = this.state.client
			client.disability_info = ""
			this.setState({client})
		}
		if (this.state.client.birthdate) {
			let client = this.state.client
			console.log(this.state.client.birthdate)
			var bday = stringToDate(this.state.client.birthdate,"mm/dd/yyyy","/")
			client.birthdate = bday
			this.setState({client})
			console.log(bday)
		}
		if (this.state.client.case_opened) {
			let client = this.state.client
			console.log(this.state.client.case_opened)
			var bday = stringToDate(this.state.client.case_opened,"mm/dd/yyyy","/")
			client.case_opened = bday
			this.setState({client})
			console.log(bday)
		}
		var data = this.state.client
		console.log(data);
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res.data.message)
				console.log("updated client")
				this.props.close();
			}
		});
	}

	handleChange = event => {
		var client = this.state.client
		client[event.target.id] = event.target.value
		this.setState({
			client
		});
	};

	handleState = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};
	
	getclassifications() {
		// const history = this.props.history;
		var url = API_URL + "/api/v1/classifications";
		axios.get(url, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(({ data }) => {
				var dataSet = [];
				console.log(data);
				data.classifications.map(function(v,i){
					// console.log(v);
				 return dataSet.push({label: v.name, value: v.id});
				});
				this.setState({
					classifications: dataSet,
				});
			});
	}

	componentWillMount(){
		// this.getclassifications();
		if (this.props.client.address2 === null)
			this.props.client.address2 = ""
		if (this.props.client.cin === null)
			this.props.client.cin = ""
		if (this.props.client.phone === null)
			this.props.client.phone = ""
		if (this.props.client.mailing_address1 !== null && this.props.client.mailing_address1 !== "" )
			var differentMailing = true

		var disability = this.props.client.disability
		var classification_id = this.props.client.classification_id
		var client = this.props.client
		var bday = formatDate(client.birthdate,"-")
		if ( client.birthdate != null ) {
			var parts = client.birthdate.split('-');
			// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
			// January - 0, February - 1, etc.
			var mydate = new Date(parts[0], parts[1] - 1, parts[2]); 
			var bday = mydate.toLocaleDateString("en-US", {
	  		year: "numeric",
	  		month: "2-digit",
	  		day: "2-digit",
			})
			client.birthdate = bday
		}
		var case_opened_day = formatDate(client.case_opened,"-")
		if ( client.case_opened != null ) {
			var parts2 = client.case_opened.split('-');
			// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
			// January - 0, February - 1, etc.
			var mydate2 = new Date(parts2[0], parts2[1] - 1, parts2[2]); 
			console.log(mydate2)
			var case_opened_day = mydate2.toLocaleDateString("en-US", {
	  		year: "numeric",
	  		month: "2-digit",
	  		day: "2-digit",
			})
			client.case_opened = case_opened_day
		}
		this.getclassifications();
		this.setState({client, disability, differentMailing, classification_id,bday,case_opened_day})
	}

	render() {
		return (
			<ScrollPanel style={{width: '100%', height: '50vh',paddingBottom:'0'}}>
				<div className="p-grid">
					<div className="p-col-12 client-info">
						<div className="card">
							<div className="p-grid">
								<div className="p-col-6">
									<div>
										<label htmlFor="account">Account</label><br/>
										<InputText id='account' onChange={this.handleChange} value={this.state.client.account ? this.state.client.account : this.props.client.account } required={true}/>
									</div>
									<div>
										<label htmlFor="firstname">First Name</label><br/>
										<InputText id='firstname' onChange={this.handleChange} value={this.state.client.firstname ? this.state.client.firstname : this.props.client.firstname } required={true}/>
									</div>
									<div>
										<label htmlFor="lastname">Last Name</label><br/>
										<InputText id='lastname' onChange={this.handleChange} value={this.state.client.lastname ? this.state.client.lastname : this.props.client.lastname }  required={true}/>
									</div>
									<div>
										<label htmlFor="address1">Address Line 1</label><br/>
										<InputText id='address1' onChange={this.handleChange} value={this.state.client.address1 ? this.state.client.address1 : this.props.client.address1 }  required={true}/>
									</div>
									<div>
										<label htmlFor="address2">Address Line 2</label><br/>
										<InputText id='address2' onChange={this.handleChange} value={this.state.client.address2 ? this.state.client.address2 : this.props.client.address2 ? this.props.client.address2 : "" }  required={true}/>
									</div>
									<div>
										<label htmlFor="city">City</label><br/>
										<InputText id='city' onChange={this.handleChange} value={this.state.client.city ? this.state.client.city : this.props.client.city }  required={true}/>
									</div>
									<div>
										<label htmlFor="state">State</label><br/>
										<InputText id='state' onChange={this.handleChange} value={this.state.client.state ? this.state.client.state : this.props.client.state }  required={true}/>
									</div>
									<div>
										<label htmlFor="zip">Zip</label><br/>
										<InputText id='zip' onChange={this.handleChange} value={this.state.client.zip ? this.state.client.zip : this.props.client.zip }  required={true}/>
									</div>
									<div>
										<label htmlFor="county">County</label><br/>
										<InputText id='county' onChange={this.handleChange} value={this.state.client.county ? this.state.client.county : this.props.client.county ? this.props.client.county : "" }  required={true}/>
									</div>
									<div>
										<label htmlFor="differentMailing">Different Mailing Address?</label><br/>
										<InputSwitch id='differentMailing' onChange={this.handleState} checked={this.state.differentMailing}/>
									</div>
									{this.state.differentMailing !== undefined && this.state.differentMailing !== false && this.state.differentMailing !== "" ?
										<React.Fragment>
											<div>
												<label htmlFor="mailing_address1">Mailing Address Line 1</label><br/>
												<InputText id='mailing_address1' onChange={this.handleChange} value={this.state.client.mailing_address1}  required={true}/>
											</div>
											<div>
												<label htmlFor="mailing_address2">Mailing Address Line 2</label><br/>
												<InputText id='mailing_address2' onChange={this.handleChange} value={this.state.client.mailing_address2 ? this.state.client.mailing_address2 : this.props.client.mailing_address2 }  required={true}/>
											</div>
											<div>
												<label htmlFor="mailing_city">Mailing City</label><br/>
												<InputText id='mailing_city' onChange={this.handleChange} value={this.state.client.mailing_city ? this.state.client.mailing_city : this.props.client.mailing_city }  required={true}/>
											</div>
											<div>
												<label htmlFor="mailing_state">Mailing State</label><br/>
												<InputText id='mailing_state' onChange={this.handleChange} value={this.state.client.mailing_state ? this.state.client.mailing_state : this.props.client.mailing_state }  required={true}/>
											</div>
											<div>
												<label htmlFor="mailing_zip">Mailing Zip</label><br/>
												<InputText id='mailing_zip' onChange={this.handleChange} value={this.state.client.mailing_zip ? this.state.client.mailing_zip : this.props.client.mailing_zip }  required={true}/>
											</div>
										</React.Fragment>
									:
										""
									}
								</div>
								<div className="p-col-6">
									<div>
										<label htmlFor="case_opened">Case Opened</label><br/>
										<InputMask mask="99/99/9999" id="case_opened" onChange={this.handleChange} value={this.state.client.case_opened ? this.state.client.case_opened : this.props.client.case_opened } required={true}/>
									</div>
									<div>
										<label htmlFor="social_security_number">Social Security Number</label><br/>
										<InputMask mask="999-99-9999" id="social_security_number" onChange={this.handleChange} value={this.state.social_security_number ? this.state.social_security_number : this.props.client.social_security_number } required={true}/>
									</div>
									<div>
										<label htmlFor="birthdate">Date of Birth</label><br/>
										<InputMask mask="99/99/9999" id="birthdate" onChange={this.handleChange} value={this.state.client.birthdate ? this.state.client.birthdate : this.props.client.birthdate } required={true}/>
									</div>
									<div>
										<label htmlFor="legal_server_email">Legal Server Email</label><br/>
										<InputText id="legal_server_email" onChange={this.handleChange} value={this.state.client.legal_server_email ? this.state.client.legal_server_email : this.props.client.legal_server_email } required={true}/>
									</div>
									<div>
										<label htmlFor="phone">Phone</label><br/>
										<InputText id="phone" onChange={this.handleChange} value={this.state.client.phone ? this.state.client.phone : this.props.client.phone } required={true}/>
									</div>
									<div>
										<label htmlFor="cin">Medicaid Case Number</label><br/>
										<InputText id="cin" onChange={this.handleChange} value={this.state.client.cin ? this.state.client.cin : this.props.client.cin ? this.props.client.cin : "" } required={true}/>
									</div>
									<div>
										<label htmlFor="primary_contact_relationship">Primary Contact</label><br/>
										<InputText id="primary_contact_relationship" onChange={this.handleChange} value={this.state.client.primary_contact_relationship ? this.state.client.primary_contact_relationship : this.props.client.primary_contact_relationship ? this.props.client.primary_contact_relationship : "" } required={true}/>
									</div>
									<div>
										<label htmlFor="primary_contact_phone">Primary Contact Phone</label><br/>
										<InputText id="primary_contact_phone" onChange={this.handleChange} value={this.state.client.primary_contact_phone ? this.state.client.primary_contact_phone : this.props.client.primary_contact_phone } required={true}/>
									</div>
									<div>
										<label htmlFor="msd_amount">Monthly Spend Down</label><br/>
										<InputText id="msd_amount" onChange={this.handleChange} value={this.state.client.msd_amount ? this.state.client.msd_amount : this.props.client.msd_amount ? this.props.client.msd_amount : "" } />
									</div>
									<div>
										<label htmlFor="zip">Classification</label><br/>
										<Dropdown 
											id="classification_id"
											name="classification_id"
											options={this.state.classifications}
											value={this.state.classification_id}
											onChange={event => this.setState({classification_id: event.value})}
											autoWidth={true}
											style={{width:"100%"}}
											placeholder="Select a Classification" />
									</div>
									<div>
										<label htmlFor="disability">Disability?</label><br/>
										<InputSwitch id='disability' onChange={this.handleState} checked={this.state.disability}/>
									</div>
									{this.state.disability ?
										<div>
											<label htmlFor="disability">Nature of Disablity</label><br/>
											<InputText id="disability_info" onChange={this.handleChange} value={this.state.client.disability_info ? this.state.client.disability_info : this.props.client.disability_info } />
										</div>
									:
										""
									}
									<div>
										<label htmlFor="burial_account">Burial Account?</label><br/>
										<InputSwitch id='burial_account' onChange={this.handleChange} checked={this.state.client.burial_account}/>
									</div>
									<div>
										<label htmlFor="ssi_beneficiary">SSI Benificiary?</label><br/>
										<InputSwitch id='ssi_beneficiary' onChange={this.handleChange} checked={this.state.client.ssi_beneficiary}/>
									</div>
									<div>
										<label htmlFor="truelink">True Link?</label><br/>
										<InputSwitch id='truelink' onChange={this.handleChange} checked={this.state.client.truelink}/>
									</div>
								</div>
							</div>
							<div className="p-grid">
								<div className="p-col-6">
									<div style={{textAlign: "center",marginTop:"20px"}}>
										<Button className="default-btn p-button-success" onClick={() => this.updateClient() } label="Update" />
									</div>
								</div>
								<div className="p-col-6">
									<div style={{textAlign: "center",marginTop:"20px"}}>
										<Button className="default-btn p-button-danger" onClick={() => this.props.close()} label="Cancel" />
									</div> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</ScrollPanel>
		);
	}
}