import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {ContextMenu} from 'primereact/contextmenu';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {formatMoney} from '../helpers/formats'
import {InputTextarea} from 'primereact/inputtextarea';
import {ScrollPanel} from 'primereact/scrollpanel';

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';


export class CaseNotes extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			
		}
		this.export = this.export.bind(this);
	}

	export() {
		this.dt.exportCSV();
	}

	render(){

		var header = <div style={{'textAlign':'left'}}>
			<i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
			<InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50" style={{width:"unset"}}/>
			<span style={{textAlign:'left',float:"right"}}><Button className="export-button" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export} style={{margin:"0px !important"}}></Button></span>
		</div>;

		return (
			<ScrollPanel style={{width: '100%', height: '50vh',paddingBottom:'0'}}>
					<ContextMenu className={"transactions-context-menu"} style={{zIndex: 3000}} model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
					<DataTable
						
						value={this.props.notes}
						selectionMode="single"
						scrollable={true}
						scrollHeight="60vh"
						header={header}
						globalFilter={this.state.globalFilter}
						ref={(el) => { this.dt = el; }}
					>
						<Column field="created_at" header="Posted On" sortable={true} style={{width:"150px"}} />
						<Column field="user" header="Posted By" sortable={true} style={{width:"150px"}} />
						<Column field="note" header="Note" sortable={true} style={{textAlign: "left"}} />
					</DataTable>
			</ScrollPanel>
		);
	}
}
