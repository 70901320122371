import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {Button} from 'primereact/button'
import {DataTable} from 'primereact/datatable';
// import {DataView, DataViewLayoutOptions} from 'primereact/dataview';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {API_URL} from '../helpers/api_helpers'
import axios, {isCancel, AxiosError} from 'axios';

export class ClientList extends Component {
	static propTypes = {
    trust: PropTypes.object,
  };

	constructor(props) {
		super(props);
		this.state = {
			dataTableValue: [],
			dataTableOriginalValue: [],
			dataTableSelection: null,
			globalFilterLayer: null,
		}
		this.selectUser = this.selectUser.bind(this)
		this.export = this.export.bind(this)
	}

	export() {
		this.dt.exportCSV();
	}

	globalSearch = () => {
		const searchPredicate = this.state.globalFilter;

		if (!searchPredicate?.trim().length) {
			this.setState({globalFilterLayer: null });
			this.getClients();
			return;
		}


		const url = API_URL + `/api/v1/clients?search=${searchPredicate}`;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
			.then(( res ) => {
				if (res) {
					this.setState({
						globalFilterLayer: '',
						dataTableValue: res.data.clients
					});
				}
			});
	}

	componentDidMount(){
		this.getClients();
	}

	selectUser(event) {
		this.state.dataTableSelection === event.value ? this.setState({dataTableSelection: null}) : 
			this.props.history.push({
				pathname: '/client',
				state: { id: event.value.id, active: this.props.active, trust: this.props.trust }
			})
	}

	getClients(){
		var trust = this.props.trust.id
		var status = this.props.status
		var url = API_URL + "/api/v1/clients?trust_id=" + trust + "&status=" + status ;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.setState({
					dataTableOriginalValue: res.data.clients,
					dataTableValue: res.data.clients
				});
			}
		});
	}

	actionTemplate(rowData, column) {
		var status = ''
		if(rowData.documents_scanned == true){ status = 'Documents Scanned' }
		if(rowData.documents_approved == true){ status = 'Documents Approved' }
		if(rowData.board_approved == true){ status = 'Board Approved' }
		return <div>
			{status}
		</div>;
	}

	render() {
		const statusDisplay = this.props.status === 'pending' ? " Pending " : this.props.status === 'disable' ? " Closed " : ""

		var header =

			<div style={{'textAlign':'left'}}>
				<i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
				<InputText type="search" onInput={(e) => {
					const newState = {
						globalFilter: e.target.value,
						globalFilterLayer: null,
					}

					if (this.state.globalFilterLayer !== null) {
						newState.dataTableValue = this.state.dataTableOriginalValue;
					}

					this.setState(newState);
				}} placeholder="Search" size="50" style={{width:"unset"}}/>
				<Button type="button" label="Global Search" onClick={this.globalSearch}></Button>
				<span style={{float:'right'}}>
					<Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
				</span>
			</div>

		return (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card">
						<h1>{this.props.trust.name + statusDisplay} Clients</h1>
						<DataTable 
							value={this.state.dataTableValue}
							selection={this.state.dataTableSelection}
							selectionMode="single"
							responsive={true}
							onSelectionChange={event => this.selectUser(event)}
							header={header}
							globalFilter={this.state.globalFilterLayer ?? this.state.globalFilter}
							ref={(el) => { this.dt = el; }}
						>
							<Column key='account'  field="account" header="Account #" sortable={true} />
							<Column key="firstname" field="firstname" header="First Name" sortable={true} />
							<Column key="lastname" field="lastname" header="Last Name" sortable={true} />
							<Column key="ssi" field="ssi" header="SSI" sortable={true} />
							{
								this.props.status === 'pending' ?
									<Column body={this.actionTemplate} header="Status" />
								:
									<Column field="status" header="Status" />
							}
							{
								this.props.status === 'disable' ?
									<Column field="closed_reason" header="Reason for Closing" />
								:
									<Column field="balance" header="Approx. Balance" sortable={true} />
							}
						</DataTable>
					</div>
				</div>
			</div>
		);
	}
}
