import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea';
import {Growl} from 'primereact/growl';
import {ScrollPanel} from 'primereact/scrollpanel';

import {API_URL} from '../helpers/api_helpers'
import {formatMoney, formatDate} from '../helpers/formats'

import './summary.css'

import axios from 'axios';


export class Summary extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
			payments: [],
			deposits: [],
			fees: [],
			paymentsArray: [],
			depositsArray: [],
			feesArray: [],
			notes: [],
			closed_reason: "",
			delete_reason: "",
		}
		this.getSumarry = this.getSumarry.bind(this)
		this.printSumary = this.printSumary.bind(this)
		this.closeAccount = this.closeAccount.bind(this)
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}
	
	UNSAFE_componentWillMount(){	
		this.getSumarry()
		this.getClientNotes()
	}

	showError(message) {
		let msg = {severity: 'error', summary: message, detail: 'Validation failed'};
		this.growl.show(msg);
	}

	getClientNotes(){
		var url = API_URL + "/api/v1/additional_notes?client_id=" + this.props.client.id;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				if (res.data.length === 0) {
					this.setState({notes: [] });
				} else {
					this.setState({notes: res.data.additional_notes });	
				}
			}
		});
	}

	buildNotes(){
		if (this.state.notes.length > 0) {
			var that = this;
			var notes = this.state.notes.map(function (key,value) {
				return (
					<div key={value}>
						<h2 style={{display: "inline-block"}}>
							{key.note}
						</h2>
					</div>
				)
			});
			return notes
		}
	}

	getSumarry(){
		var url = API_URL + "/api/v1/summary_payments";
		var data = {
			client_id: this.props.client.id,
		};
		return axios.get(url,{ params: data,
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var paymentsArray = []
				var depositsArray = []
				var feesArray = []
				var payments = res.data.summary_payments.map((e,i) => {
					
						if( e.payment_type === 1 || e.payment_type === 2){
							paymentsArray.push(e)
							return (
								<div className="summary-payment" key={i}>
									{e.payee_name_line1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{e.amount}</b><br/>
									{e.street_name}<br/>
									{e.city}, {e.state_province} {e.zip_postal_code}<br/>
								</div>	
							)
						}
				})
				var deposits = res.data.summary_payments.map((e,i) =>{
					if( e.payment_type === 0){
							depositsArray.push(e)
							return (
								<div className="summary-payment" key={i}>
									{e.payee_name_line1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{e.amount}</b><br/>
								</div>	
							)
						}
				})
				var fees = res.data.summary_payments.map((e,i) => {
					if( e.payment_type === 10){
							feesArray.push(e)
							return (
								<div className="summary-payment" key={i}>
									{e.payee_name_line1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{e.amount}</b><br/>
									{e.fee_exception !== "" && e.fee_exception !== undefined && e.fee_exception !== null ?
										e.fee_exception + "\n"
									:
										""
									}
								</div>	
							)
						}
				})
				this.setState({payments: payments,deposits: deposits,fees: fees,paymentsArray: paymentsArray,depositsArray: depositsArray,feesArray: feesArray})
			}
		});
	}

	closeAccount(){
		const history = this.props.history;
		var event = ''
		var errors = false
		var status = this.props.client.status
		if (status === 'pending'){
			event = 'cancel'
		}
		if (status === 'active'){
			event = 'deactivate'
		}
		if (this.state.closed_reason === ""){
			this.showError('Reason can not be blank!')
			errors = true
		}
		if ( errors === true){
			return
		}
		var url = API_URL + "/api/v1/clients/" + this.props.client.id ;
		var data = { client: {status_event: event, closed_reason: this.state.closed_reason}}
		data.id = this.props.client.id;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				let msg = {severity: 'success', summary: 'Success Message', detail: 'Client Updated'};
    		// and after timeout
		    window.setTimeout(() => {
		       history.push("/"+this.props.client.trust.name.replace(" ","-").toLowerCase())
		       // history is available by design in this.props when using react-router
		    }, 1000) // 1000 means 3 seconds
			}
		});
	}

	deleteAccount(){
		const history = this.props.history;
		var status = this.props.client.status
		if (this.state.delete_reason === ""){
			this.showError('Reason can not be blank!')
			return
		}
		var url = API_URL + "/api/v1/clients/" + this.props.client.id ;
		var data = { client: {status_event: "delete", delete_reason: this.state.delete_reason, deleted_by: sessionStorage.getItem('name')}}
		data.id = this.props.client.id;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				let msg = {severity: 'success', summary: 'Success Message', detail: 'Client Updated'};
    		// and after timeout
		    window.setTimeout(() => {
		       history.push("/")
		       // history is available by design in this.props when using react-router
		    }, 1000) // 1000 means 3 seconds
			}
		});
	}

	reopenAccount(){
		const history = this.props.history;
		var status = this.props.client.status
		var url = API_URL + "/api/v1/clients/" + this.props.client.id ;
		var data = { client: {status_event: "reactivate"}}
		data.id = this.props.client.id;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				let msg = {severity: 'success', summary: 'Success Message', detail: 'Client Updated'};
    		// and after timeout
		    window.setTimeout(() => {
		       history.push("/"+this.props.client.trust.name.replace(" ","-").toLowerCase())
		       // history is available by design in this.props when using react-router
		    }, 1000) // 1000 means 3 seconds
			}
		});
	}

	printSumary(){
		var that = this
		var data = {
			client: this.props.client,
			payments: this.state.paymentsArray,
			deposits: this.state.depositsArray,
			fees: this.state.feesArray,
			notes: this.state.notes,
			template: "SummaryTemplate"
		}
		var url = API_URL + "/api/v1/generate"
		var file = axios.post(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			},
			responseType: 'blob' //Force to receive data in a Blob Format
		})
		.then(( res ) => {
			if (res) {
				console.log("Summary Printed")
				var date = new Date()
				var account = that.props.client.lastname + "_" + that.props.client.account 
				var filename = API_URL + "/" + account + "/" + "Client_Summary_" + account + "_" +formatDate(date,"_") + ".pdf"
				// console.log(res)
				// regenerate all Recuring Payements with Deposit.
				that.regeneratePayments()
				//Create a Blob from the PDF Stream
				const file = new Blob(
				  [res.data], 
				  {type: 'application/pdf'});
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				//Open the URL on new Window
				window.open(fileURL);
			}
		});
	}

	regeneratePayments(){
		var url = API_URL + "/api/v1/payments"
		var data = {
			client_id: this.props.client.id,
			payment_type: 1,
		}

		axios.get(url,{ params: data,
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) { 
				// console.log(res)
				res.data.payments.forEach((payment) => {	
					var url = API_URL + "/api/v1/summary_payments"
					payment.payment_id = payment.id
					axios.post(url, payment, {
						headers: {
							Authorization: sessionStorage.getItem('jwtToken'),
						} 
					})
				})
			}
		})
	}

	render(){
		const notes = this.buildNotes()
		var permissions = JSON.parse(sessionStorage.permissions)
		
		return (
			<ScrollPanel style={{width: '100%', height: '50vh',paddingBottom:'0'}}>
				<div className="p-grid">
					<div className="p-col-12">
						<Growl ref={(el) => this.growl = el} />
						<div>
							<h2>{this.props.client.account}</h2><br/>
						</div>
						<div>
							<h2>{this.props.client.firstname + " " + this.props.client.lastname}</h2><br/>
						</div>
						<div>
							<h2 style={{ borderBottom: "1px solid #e5e5e5", marginBottom: "5px",width: "100%", minHeight: "35px"}}>Notes</h2>
							{notes}
						</div><br/>
						<div>
							<h2 style={{ borderBottom: "1px solid #e5e5e5", marginBottom: "5px",width: "100%", minHeight: "35px"}}>Deposits</h2>
							{this.state.deposits}
						</div><br/>
						<div>
							<h2 style={{ borderBottom: "1px solid #e5e5e5", marginBottom: "5px",width: "100%", minHeight: "35px"}}>Fees</h2>
							{this.state.fees}
						</div><br/>
						<div>
							<h2 style={{ borderBottom: "1px solid #e5e5e5", marginBottom: "5px",width: "100%", minHeight: "35px"}}>Payments</h2>
							{this.state.payments}
						</div>
						<Button label="Print" style={{float:"left"}} onClick={this.printSumary} />
						{ permissions.closed_clients === true && this.props.client.status !== 'disable' ?
							<div className='close_account'>
								<Button label="Close Account" className={"p-button-danger"} style={{float:"right"}} onClick={(e) => { if (window.confirm('Are you sure you wish to close this account?')) this.closeAccount() }}/>
								<InputTextarea id='closed_reason' rows={5} onChange={this.handleChange} required={true} autoResize={true} placeholder='Reason to close the Account' />
							</div>
						:
							""
						}
						{ permissions.closed_clients === true && this.props.client.status === 'disable' ?
							<React.Fragment>
								<div className='close_account'>
									<Button label="Delete Account" className={"p-button-danger"} style={{float:"right"}} onClick={(e) => { if (window.confirm('Are you sure you wish to delete this account?')) this.deleteAccount() }}/>
									<InputTextarea id='delete_reason' rows={5} onChange={this.handleChange} required={true} autoResize={true} placeholder='Reason to delete the Account' />
								</div>
								<div className='reopen_account'>
									<Button label="Reopen Account" className={"p-button-danger"} style={{float:"right"}} onClick={(e) => { if (window.confirm('Are you sure you wish to reopen this account?')) this.reopenAccount() }}/>
									<InputTextarea id='reopen_account' rows={5} onChange={this.handleChange} required={true} autoResize={true} placeholder='Reason to reopen the Account' />
								</div>
							</React.Fragment>
						:
							""
						}
					</div>
				</div>
			</ScrollPanel>	
		);
	}
}
