import React, {Component} from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {InputText} from "primereact/inputtext";
// import {Password} from 'primereact/password';
// import {Dropdown} from 'primereact/dropdown';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
// import {DataView, DataViewLayoutOptions} from 'primereact/dataview';
import {Column} from 'primereact/column';
import {Growl} from 'primereact/growl';
import {API_URL} from '../../helpers/api_helpers'

import axios, {isCancel, AxiosError} from 'axios';

export class TrueLinkClients extends Component {
	constructor(props) {
		super(props);

		this.state = {
      
		};
	}

	componentDidMount() {
		this.getClients();
	}

	selectUser(event) {
		this.state.dataTableSelection === event.value ? this.setState({dataTableSelection: null}) : 
			this.props.history.push({
				pathname: '/true-link/' + event.value.id,
			})
	}

	getClients(){
		var status = this.props.status
		var url = API_URL + "/api/v1/clients?&status=active&truelink=true" ;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.setState({dataTableValue: res.data.clients });
			}
		});
	}

	createExport(){
		console.log("EXPORT:")
		var url = API_URL + "/api/v1/true_link/generate";
		axios.post(url, {test: 'test'}, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			console.log(res)
			this.download(res)
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
	}

	download(response){
		// DOWNLOAD THE FILE
    // url = API_URL + "/api/v1/export_files/" + file_id + "/download"
    // axios.get(url,{
    //   headers: {
    //     Authorization: sessionStorage.getItem('jwtToken'),
    //   },
    //   responseType: 'blob', // important
    // }).then((response) => {
      // const url = window.URL.createObjectURL(new Blob([response.data.filename]));
      const link = document.createElement('a');
      link.href = response.data.filename;
      link.setAttribute('download', "text.xlsx" );
      document.body.appendChild(link);
      link.click();
      link.remove();
    // });
	}
	render() {

		var header = <div style={{'textAlign':'left'}}>
                        <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                        <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50" style={{width:"unset"}}/>
                        <Button style={{float:"right"}} onClick={(e) => this.createExport()} label="Export" />
                    </div>;
    
		return (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card">
						<div className="card-header">
							<h1>True Link Clients</h1>
							<DataTable 
							value={this.state.dataTableValue}
							selection={this.state.dataTableSelection}
							selectionMode="single"
							responsive={true}
							onSelectionChange={event => this.selectUser(event)}
							header={header}
							globalFilter={this.state.globalFilter}
						>
							<Column field="account" header="Account #" sortable={true} />
							<Column field="lastname" header="Last Name" sortable={true} />
							<Column field="firstname" header="First Name" sortable={true} />
							<Column field="ssi" header="SSI" sortable={true} />
							<Column field="balance" header="Approx. Balance" sortable={true} />
						</DataTable>
						</div>
					</div>
				</div>
			</div>
		);
	}
}