import React, {Component} from 'react';
import {Messages} from 'primereact/messages';
import {Message} from 'primereact/message';
// import PropTypes from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {ContextMenu} from 'primereact/contextmenu';

import {API_URL} from '../helpers/api_helpers'
import axios from 'axios';

export class TransactionsFee extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dataTableValue: "",
			dataTableSelection: "",
			showFeeModal: false,
			contextItems: [],
			menu: [],
			depositMethod: [
                {label: 'Check (Non Security)', value: 'Check (Non Security)'},
                {label: 'Chips Instruction', value: 'Chips Instruction'},
                {label: 'Credit Commercial Account', value: 'Credit Commercial Account'},
                {label: 'Fed Funds', value: 'Fed Funds'},
                {label: 'Nacha Instruction', value: 'Nacha Instruction'}
      ],
      dropdownDepositMethod: "Check (Non Security)",
      destinationCodeDropdown: [
      					{label: "Check (Non Security)", value: 106},
      					{label: "Bill Pay", value: 0},
      					{label: "Special Handling", value: 117},
      					{label: "Send with Invoice", value: 107},
      					{label: "FedEx", value: 114},
      ],
      destinationCodeSelection: 106,
		}
		this.rowClassName = this.rowClassName.bind(this);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	componentWillMount(){
		this.setState({files: this.props.files})
		this.getExportFileCategories();
		var permissions = JSON.parse(sessionStorage.permissions)

		if ( permissions.payments_role === true ) {
			var menu = [
				{label: 'Export', icon: 'pi pi-fw pi-sign-in' },
				{label: 'Remove', className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to remove this export?')) this.removeFee(this.state.contextSelection,event) } },
				{label: 'Delete', className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(event,this.state.contextSelection,this.props.title) } },
			]
		} else {
			var menu = []
		}
		this.setState({menu})
	}

	// componentDidMount(){
	// }

	getExportFileCategories(){
		var url = API_URL + "/api/v1/export_file_categories";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var items = [];
				var items2 = []
				var that = this;
				res.data.export_file_categories.map(function(value,key){
					if( value.name === that.props.title || (value.name === "Management Approval" && that.props.title !== "Fees")) {
						if(value.parent_id === null) {
							var files = that.build_list(value);
							var files2 = that.build_remove_list(value)
							var item = {label: value.name, items: files}
							var item2 = {label: value.name, items: files2}
							items.push(item);
							items2.push(item2)
						}
					} else if (value.name === "Fees" && that.props.title !== "Deposits") {
						if(value.parent_id === null) {
							files = that.build_list(value);
							files2 = that.build_remove_list(value)
							item = {label: value.name, items: files}
							item2 = {label: value.name, items: files2}
							items.push(item);
							items2.push(item2)
						}
					}
					return null;	
				});
				var menu = this.state.menu
				if ( menu[0] !== undefined ) {
					menu[0].items = items
				}
				if ( menu[1] !== undefined ){
					menu[1].items = items2
				}
				this.setState({menu: menu});
			}
		});
	}

	build_list(category){
		if(this.props.files){
			var list = [];
			this.props.files.forEach((file,index) => {
				if ( file.category_id === category.id){
					list.push({label: file.name, command: (event) => this.export(this.state.contextSelection,category,event), file_id: file.id})
				}
			})
		}
		return list;
	}

	build_remove_list(category){
		if(this.props.files){
			var list = [];
			this.props.files.forEach((file,index) => {
				if ( file.category_id === category.id){
					list.push({label: file.name, command: (event) => this.removeFee(this.state.contextSelection,category,event), file_id: file.id})
				}
			})
		}
		return list;
	}

	export(content,category,event){
		console.log(content)
		var payment_type = "";
		switch(category.name){
			case "Management Approval":
				payment_type = 'approval';
				break;
			case "Disbursements":
				payment_type = 'payment';
				break;
			case "Fees":
				payment_type = 'fee';
				break;
			case "Deposits":
				payment_type = 'deposit';
				break;
			default:
				//
		}
		var data = {
			export_file: {
				name: event.item.label,
				data_type: payment_type,
				connecting_object: {
					comment: "string",
					object_id: content.id
				}
			}
		}
		console.log(data);
		var file_id = event.item.file_id;
		console.log(file_id);
		var url = API_URL + "/api/v1/export_files/" + file_id;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.messages.show({severity: 'success', summary: 'SUCCESS!', detail: 'Fee exported to file!'});
				console.log(res)
				console.log("added item to export")
				if(content){
					content.payment_type = 10
					content.payee_name_line1 = "Fee"
					content.fee_exception = content.exception
					content.client_id = this.props.client.id
					var url = API_URL + "/api/v1/summary_payments/"
					axios.post(url,content,{
						headers: {
							Authorization: sessionStorage.getItem('jwtToken'),
						} 
					})
					.then(( res ) => {
						if (res) {
							console.log("added item to summary payments")
						}
					});
				}
				// Then we update the client pending balance if it's not an approval file
				if(content){
					if(payment_type != 'approval' ){
						var url = API_URL + "/api/v1/clients/" + content.client_id + "/update_balance"
						data = {
							client: {
								amount: -(content.amount),
							}
						}
						axios.put(url,data,{
							headers: {
								Authorization: sessionStorage.getItem('jwtToken'),
							} 
						})
						.then(( res ) => {
							if (res) {
								console.log("client balance updated")
							}
						});
					}
				}
			}
		})
		.catch((error) =>  {
			if( this.messages ){
				this.messages.show({severity: 'error', summary: 'FAILED!', detail: 'Failed to export to file!'});
			}
		});
	}

	addFee(){
		this.props.addFee(this.state.Amount,this.state.description)
		this.setState({showFeeModal: false});
		this.props.update();
	}

	removeFee(content,event){
		const file_id = content.export_file_ids[0];
		const url = API_URL + "/api/v1/export_files/" + file_id;
		axios.delete(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log("item deleted from export")
			}
		});
		this.props.update();
	}

	deleteRow(event,row,content){
		console.log("JO")
		console.log("DELETE ROW", row, this.props.dataTableValue);

    	const url = API_URL + "/api/v1/fees/" + row.id ;
		axios.delete(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			if (res) {
				console.log("Row Deleted")
			}
		});

		this.props.update();
	}

	resetFee(form){
		console.log("In Reset!")
		document.getElementById(form).reset();
		this.setState({
			Amount: "",
			ExplanationLine1: "",
			showFeeModal: true,
		});
	}

	rowClassName(rowData) {
		let brand = rowData.export_files;
		var cName = ""
		if (brand && brand.length > 0){
			cName = brand[brand.length -1].data_type === 'approval' ? {'p-highlight-a' : "a"} : {'p-highlight-b' : "b"} ;
		}
		return cName
	}

	render() {
		const title = this.props.title === "Deposits" ? "Deposit" : this.props.title === "Fees" ? "Fee" : "Payment"
		const dialogHeader = (
      <div>
        <h1>New Fee</h1>
      </div>
    );

    const dialogFooter = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={() => this.addFee() }
          label="Create"
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({showFeeModal: false}) }
          label="Cancel"
        />
      </div>
    );

    var permissions = JSON.parse(sessionStorage.permissions)

		return (
			<div className="p-col-12 deposit-info">
				<Messages ref={(el) => this.messages = el}></Messages>
				<div className="card">
					<div className="deposit-header">
						<h1>{this.props.title}</h1>
						{ permissions.add_fee === true ?
							<button className="p-button p-button-warning default-btn" onClick={() => this.resetFee(title+"-form") }>Add {title}</button>
						:
							""
						}
					</div>
					<React.Fragment>
						<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>

						<DataTable
						rowKey="id"
						value={this.props.dataTableValue}
						selectionMode="single"
						resizableColumns={true}
						selection={this.props.dataTableSelection}
						onSelectionChange={event => this.setState({dataTableSelection: event.value})}
						contextMenuSelection={this.state.contextSelection} 
						onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
						onContextMenu={e => this.cm.show(e.originalEvent)}
					>
							<Column key="amount" field="amount" header="Amount" sortable={false} />
							<Column key="description" field="description" header="Fee Type" sortable={false} />
							<Column key="exception" field="exception" header="Fee Exception" sortable={false} />
						</DataTable>
					</React.Fragment>
	      { /* FEE DIALOG */ }
				<Dialog
	        header={dialogHeader}
	        footer={dialogFooter}
	        visible={this.state.showFeeModal}
	        modal={true}
	        width="200px"
	        closable={true}
	        className="new-user"
	        onHide={ () => this.setState({showFeeModal: false}) }
	      >
	        <div className="card card-w-title">
	          <div
	            className="p-grid p-justify-center"
	            style={{ textAlign: "center" }}
	          >
	            <div className="p-col-12">
	              <div>
	              	<form id={title + "-form"}>
	                  <InputText id="Amount" onChange={this.handleChange} placeholder="Amount" />
	                  <InputText id="description" onChange={this.handleChange} placeholder="Description" />
	                  <InputText id="exception" onChange={this.handleChange} placeholder="Exception" />
	                </form>
	              </div>
	            </div>
	          </div>
	        </div>
	      </Dialog>
			</div>
		</div>
		)
	}
}
